const ro = {
  maintenance: {
    underMaintenance: "Nightz este în mentenanță",
    backSoon:
      "Nightz este în mentenanță pentru a putea veni cu îmbunătățiri și pentru a va oferi cea mai bună experiență. Ne pare rău pentru incovenienta, vom reveni curând!",
  },
  confirmation: {
    close: "Închide",
    delete: "Șterge",
    accept: "Accept",
    cancel: "Anulează",
    save: "Salvează",
    edit: "Editează",
    confirmDeleteDialogStandardTitle: "Șterge",
    confirmDeleteDialogStandardDescription:
      "Ești sigur că dorești să mergi mai departe cu ștergerea?",
  },

  daysOfTheWeek: {
    monday: "luni",
    tuesday: "marți",
    wednesday: "miercuri",
    thursday: "joi",
    friday: "vineri",
    saturday: "sâmbătă",
    sunday: "dumincă",
  },

  guards: {
    permissionDenied: {
      title: "Accesul interzis",
      body: "Nu aveţi permisiunea să accesaţi această pagină.",
    },
  },

  login: {
    title: "Bună, bine ai revenit!",
    header: "Autentifică-te în contul tau Nightz",
    no_account: "Nu ai un cont?",
    start_now: "Începe acum",
    submit: "Autentifică-te",
    forgot_password: "Ai uitat parola?",
    continueWithEmail: "Continuă cu email",
    fields: {
      emailOrPhone: "Email sau telefon",
      password: "Parola",
    },
    validation: {
      emailOrPhone_required: "Emailul sau telefonul este obligatoriu",
      password_required: "Parola este obligatorie",
    },
    errors: {
      IncorrectemailOrPhoneorpassword: "Credenţiale greșite",
      Toomanyfailedattemptsofloginpleaseresetyourpassword:
        "Prea multe încercări nereușite. Vă rugăm sa vă resetați parola!",
      Incorrectinfo: "Credenţiale greșite",
    },
    withSocials: {
      or: "SAU",
    },
  },

  accountMenu: {
    message:
      "Bună! Pentru a continua, te rugam să te autentifici sau să te inregistrezi",
    login: "Autentifică-te",
    register: "Înregistrează-te",
    logout: "Deconectează-te",
    home: "Acasă",
    tickets: "Bilete și Rezervări",
    profile: "Contul Meu",
    myCart: "Coșul Meu",
    myDashboard: "Panou de Control",
    invoices: "Facturi",
    invoicesDrafts: "Schițele Facturi",
    invoicesDraft: "Schiță Factură",
  },

  search: {
    notFound: "Nu a fost găsit",
    noResult: "Nu s-au găsit rezultate pentru",
    tryTypos:
      "Încercați să verificați greșelile de scriere sau să utilizați cuvinte complete.",
    enterKeywords: "Vă rugăm să introduceți cuvinte cheie",
    searchBar: "Caută",
  },

  registerPage: {
    priceRange: "Prețuri",
    viewMenu: "Deschide meniul",
    reserveTable: "Rezervă o masă",
    pageTitle: "Înregistrare",
    pageMessage: "Înregistrează-te la Nightz",
    title: "Începe absolut gratuit",
    subTitle: "Gasește petreceri peste tot ",
    accountType: "Tipul de cont",
    userType: "Utilizator",
    companyType: "Companie",
    firstName: "Prenume",
    lastName: "Nume de familie",
    emailAddress: "Adresa de email",
    password: "Parola",
    register: "Inregistrează-te",
    message1: "Prin înregistrare, sunt de acord cu Nightz",
    message2: "și",
    termsAndConditionsTitle: "Termeni și Condiții",
    termsAndConditions: "Termenii și condițiile",
    privacyPolicy: "Politica de confidențialitate",
    alreadyAccount: "Ai deja un cont?",
    login: "Autentifică-te",
    firstNameRequired: "Prenumele este obligatoriu",
    lastNameRequired: "Numele de familie este obligatoriu",
    emailAddressRequired: "Adresa de email este obligatorie",
    passwordRequired: "Parola este obligatorie",
    invalidEmail: "Adresa de email este invalida",
    genericError: "A apărut o problemă! Te rugăm să încerci mai târziu.",
  },

  userProfile: {
    title: "Contul meu",
    general: "General",
    notifications: "Notificări",
    socialLinks: "Link-uri sociale",
    changePassword: "Schimbă parola",
  },

  userTicketsPage: {
    title: "Activitățile mele",
    myAccount: "Contul meu",
    tickets: "Bilete și Rezervări",
    upcomingEvents: "Activități viitoare",
    upcomingBookings: "Rezervări viitoare",
    pastEvents: "Activități trecute",
    pastBookings: "Rezervări trecute",
    noUpcomingEventsTickets: "Nu ai activități pentru evenimente viitoare",
    noPastEventsTickets: "Nu ai activități pentru evenimente trecute",
  },

  checkoutPage: {
    cartMaxQuantity: "Nu puteți adăuga mai mult de 10 articole în coș",
  },

  accountGeneral: {
    firstName: "Prenume",
    lastName: "Nume",
    username: "Nume de utilizator",
    phoneNumber: "Număr de telefon",
    country: "Tara",
    city: "Oras",
    about: "Despre",
    saveChanges: "Salvează modificările",
    formatAllowed: "Formatul permis este *.jpeg, *.jpg, *.png",
    maxSize: "Dimensiunea maximă permisă este de {{size}}",
    dashboard: "Panou de control",
    user: "Utilizator",
    accountSettings: "Setări cont",
    updateSuccess: "Actualizare reușită!",
  },

  imageCropper: {
    cancel: "Anulează",
    remove: "Șterge",
    save: "Salvează",
    editImage: "Editează imaginea",
  },

  listingList: {
    title: "Postări",
    subtitleNightz: "Divertisment, restaurante și relaxare",
    subtitleDayz: "Business, networking & dezvoltare",
    freeEntry: "Intrare liberă",
    startingFrom: "Începând de la",
    allCountry: "Toată Ţara",
    seeMore: "Vezi mai mult",
    seeLess: "Vezi mai puțin",
  },

  bookings: {
    error: "A fost întâmpinată o eroare. Vă rugăm să incercați mai târziu.",
    errorNoPermissionCompany: "O companie nu are dreptul de a creea rezervări.",
    successReservationMade:
      "O cerere de rezervare a fost făcută cu succes. Verifică mail-ul sau telefonul pentru mai multe informații!",
    errorNotEnoughPlaces:
      "O rezervare nu poate fi făcută pentru că nu sunt suficiente locuri." +
      "Puteți contacta organizatorul pentru mai multe informații",
    contactDetails: "Detalii de contact",
    back: "Inapoi",
    continue: "Continuă",
    successCancel: "Rezervarea a fost anulată cu succes!",
    cancelError: "A apărut o eroare, vă rugăm să încercați din nou.",
    noAvailibityError:
      "Locurile s-au epuizat. Te rugăm să încerci alt interval.",
    numberOfGuest: "oaspete",
    numberOfGuests: "oaspeți",
    yes: "Da",
    no: "Nu",
    alertTitle: "Ești sigur?",
    alertText: "Sunteți sigur că doriți să anulați această rezervare?",
    addTable: "Alegeți masa",
    title: "Rezervări",
    on: "Rezervări pe",
    approve: "Aprobă",
    remove: "Elimină",
    confirmed: "Confirmat",
    pending: "În așteptare",
    waiting_for_payment: "În curs de plată",
    canceled: "Anulat",
    blocked: "Blocat",
    deleted_by_venue: "Șters de către locație",
    cancel_by_user: "Anulat",
    cancel_by_venue: "Anulat",
    denied: "Respins",
    deny: "Respinge",
    noStatus: "Fără status",
    newReservation: "Rezervare",
    blockInterval: "Blocați",
    deleteError: "A apărut o eroare, vă rugăm să încercați din nou.",
    createError: "A apărut o eroare, vă rugăm să încercați din nou.",
    rescheduleError: "A apărut o eroare, vă rugăm să încercați din nou.",
    blockError: "A apărut o eroare, vă rugăm să încercați din nou.",
    tables: "Mese",
    area: "Zona",
    guests: "Invitați",
    time: "Ora",
    date: "Data",
    save: "Salvează",
    delete: "Șterge",
    cancel: "Anulează",
    fullName: "Nume complet",
    name: "Nume",
    phoneNumber: "Număr de telefon",
    email: "Email",
    contact: "Detalii de contact",
    length: "Durata",
    type: "Scrie...",
    tooManyGuests:
      "Atenție! Capacitatea meselor este sub numărul de invitați. Rezevarea poate să fie salvată în continuare.",
    cancelReservation: "Anulați rezervarea",
    outsideBusinessHours:
      "Rezervările nu pot fi făcute în afara orelor de lucru.",
    startTimeIsInvalid: "Ora de începere este invalidă.",
    endTimeIsInvalid: "Ora de încheiere este invalidă.",
    closedDay: "Astăzi locația este închisă.",
    approveReservation: "Aprobi rezervarea?",
    deleteReservation: "Ștergi rezervarea?",
    giveUp: "Renunță",
    reservationApproved: "Rezervarea a fost aprobată!",
    reservationDeleted: "Rezervarea a fost ștearsă!",
    noAvailableBookingCardOptions:
      "Nu există opțiuni disponibile. Încearcă altă combinație.",
    loadingBookingCardOptions: "Vă rugăm să așteptați.",
    visit: "vizită",
    formValidation: {
      statusRequired: "Statusul este obligatoriu.",
      guestsRequired: "Numărul de invitați este obligatoriu.",
      guestsMin: "Obligatoriu",
      dateRequired: "Data este obligatorie.",
      startTimeRequired: "Ora de începere este obligatorie.",
      endTimeRequired: "Ora de încheiere este obligatorie.",
      areaRequired: "Zona este obligatorie.",
      tablesRequired: "Cel puțin o masă este obligatorie.",
      fullNameRequired: "Numele complet este obligatoriu.",
      phoneNumber: "Numărul de telefon este obligatoriu.",
      phoneNumberLength: "Vă rugăm să puneți un număr de telefon corect",
      emailRequired: "Adresa de email este obligatorie.",
      emailValid: "Introduceți o adresă de email validă.",
    },
    clientBookingValidation: {
      guestsRequired: "Numărul de invitați este obligatoriu.",
      dateRequired: "Data este obligatorie.",
      timeRequired: "Ora este obligatorie.",
      areaRequired: "Zona este obligatorie.",
      lengthRequired: "Durata obligatorie.",
    },
    bookingDetails: {
      title: "Detalii rezervare",
    },
    table: {
      name: "Nume",
      status: "Status",
      searchByIdNamePhoneEmail:
        "Caută după nume, email, id sau număr de telefon",
      guests: "Invitați",
      area: "Zona",
      tables: "Mese",
      time: "Data si ora rezervarii",
      phone: "Telefon",
      email: "E-Mail",
      id: "ID-ul rezervarii",
      reset: "Resetează",
      reservationsTitle: "Rezervări",
    },
    howItWorks: "Cum funcționează",
    holdingFee: "Taxă de rezervare",
    holdingFeeDisclaimer:
      "Această locație folosește o taxă de rezervare pentru a confirma mesele selectate și pentru a evita absențele. Această sumă va fi scăzută din nota de plată.",
  },
  bookingWizard: {
    title: "Alegeți preferințele",
    tickets: "Bilete",
    booking: "Masă",
    bookingMenu: "Meniu",
    seatsTitle: "Alegeți locurile",
  },

  checkout: {
    cart: {
      cart: "Coș",
      clearCart: "Golește coșul",
      clearCartSubtitle: "Ești sigur că vrei să ștergi toate produsele din coș?",
      ticketsMaxQuantity: "Nu puteți adăuga mai multe bilete!",
      increase: "Cantitatea a fost crescută",
      decrease: "Cantitatea a fost scăzută",
      itemDelete: "Produsul a fost șters cu succes!",
      itemDeleteError: "Produsul nu a putut fi șters!",
      itemUpdateError: "Produsul nu a putut fi actualizat!",
    },
    congrats: {
      "ticket": "Felicitări! Biletele au fost achiziționate cu succes!",
      "table": "Felicitări! Rezervarea a fost efectuată cu succes!",
      "order": "Felicitări! Comanda a fost efectuată cu succes!",
      "ticketAction": "Vezi biletele",
      "tableAction": "Vezi rezervarea",
      "orderAction": "Vezi detalii",
      "callToAction": "Super, mersi!"
    },
    billingAndAddress: "Date de facturare",
    title: "Pagina de plată",
    item: "articol",
    items: "articole",
    emptyCartTitle: "Coșul este gol",
    emptyCartDescription:
      "Se pare că nu ai adăugat nimic în coșul de cumpărături.",
    continueShopping: "Continuă cumpărăturile",
    orderSummary: "Rezumat comandă",
    checkout: "Confirmă comanda",
    cartMaxQuantity: "Nu puteți adăuga mai mult de 10 articole în coș!",
    acceptTermsAndConditions: "Vă rugăm să acceptați termenii și condițiile!",
    subtotal: "Subtotal",
    discount: "Reducere",
    processingFee: "Din care taxă de procesare",
    vatIncluded: "TVA inclus dacă e aplicabil.",
    discountCode: "Cod de reducere",
    applyDiscount: "Aplică",
    promoCodeApplied: "Codul de reducere a fost aplicat cu succes.",
    product: "Produs",
    products: "Produse",
    seat: "Locuri",
    price: "Preț",
    quantity: "Cantitate",
    totalPrice: "Preț total",
    ticketType: "Tipul de bilete",
    available: "Disponibile",
    changeQty: "Modifică",
    iReadAndAgree: "Am citit și sunt de acord cu",
    billing: {
      addressTitle: "Adresa de facturare",
      title:
        "Vă rugăm să completați următorul formular cu datele dumneavoastră",
      validation: {
        firstName: "Prenumele este obligatoriu",
        lastName: "Nume de familie este obligatoriu",
        postCode: "Codul poşta este obligatoriu",
        digits: "Codul poştal trebuie să conțină doar cifre",
        phoneDigits: "Numărul de telefon trebuie să conțină doar cifre",
        phoneMax: "Numărul de telefon trebuie să aibă cel mult 15 cifre",
        phoneMin: "Numărul de telefon trebuie să aibă cel putin 7 cifre",
        phone: "Numărul de telefon este obligatoriu",
        email: "Adresa de email este obligatorie",
        emailFormat: "Adresa de email este invalidă",
        emailsMatching: "Adresele de email trebuie sa corespundă",
        address: "Adresa este obligatorie",
        city: "Orașul este obligatoriu",
        state: "Județul este obligatoriu",
      },
      firstName: "Prenume",
      lastName: "Nume de familie",
      address: "Adresa de facturare",
      city: "Localitate / Oraş",
      phone: "Număr de telefon",
      email: "Adresa de email",
      validateEmail: "Confirmă adresa de email",
      state: "Județ",
      country: "Ţară",
      postCode: "Cod poştal",
      back: "Înapoi",
      continueToPayment: "Continuă către plată",
    },
    completeOrder: "Finalizează comanda",
    edit: "Modifică",
    emailDelivery: "Primește pe email",
    deliveryOptions: "Opțiuni de livrare",
    payment: {
      payment: "Plată",
      options: "Opțiuni de plată",
      title1: "Plătește cu un card nou",
      title2: "Plătește cu un card salvat",
      description:
        "Vei fi redirecționat pe pagina Banca Transilvania pentru a completa plata în siguranță.",
      cards: "Carduri",
      expiration: "Expiră în",
      newCard: "Adaugă un card nou",
      deleteCard: "Şterge cardul selectat",
      loading:
        "Vă rugăm să nu închideți fereastra. Cererea dumneavoastră este în curs de procesare.",
      cancel: "Anulează",
    },
    genericError: "A apărut o problemă! Te rugăm să încerci mai târziu.",
    errors: {
      noPromoCodeError: "Acest cod de reducere nu este valabil!",
      noOnlySpacesError: "Nu se pot folosi numai spații",
    },
    success: {
      title: "Vă mulţumim pentru achiziţia dumneavoastră!",
      order1: "Comanda",
      order2: "a fost plasată.",
      mail: "Vei primi un mail cu biletele achiziționate în curând.",
      mail2: "Vei primi un mail cu detaliile comenzii în curând.",
      download: "Alternativ, le poți descărca folosind butonul de mai jos.",
      problems: "Dacă întâmpini probleme, nu ezita să ne contactezi.",
    },
    fail: {
      title: "OOPS!",
      message1: "Se pare că a apărut o eroare la plasarea comenzii.",
      message2: "Te rugăm să încerci mai târziu.",
      messageCode_104: "Card restricționat (blocat temporar sau permanent)",
      messageCode_124:
        "Tranzacția nu poate fi autorizată din cauza acordului guvernului, băncii centrale sau instituției financiare, legi sau reglementări",
      messageCode_320: "Card inactiv. Vă rugăm activați cardul.",
      messageCode_801: "Emitent indisponibil.",
      messageCode_803:
        "Card blocat. Contactați banca emitentă sau reîncercați tranzacția cu alt card.",
      messageCode_804:
        "Tranzacția nu este permisă. Contactați banca emitentă sau reîncercați tranzacția cu alt card.",
      messageCode_805: "Tranzacție respinsă.",
      messageCode_861: "Dată expirare card greșită.",
      messageCode_871: "CVV gresit.",
      messageCode_905: "Card invalid. Acesta nu există în baza de date.",
      messageCode_906: "Card expirat.",
      messageCode_913:
        "Tranzacție invalidă. Contactați banca emitentă sau reîncercați tranzacția cu alt card.",
      messageCode_914: "Cont invalid. Vă rugăm contactați banca emitentă.",
      messageCode_915: "Fonduri insuficiente.",
      messageCode_917: "Limită tranzacționare depășită.",
      messageCode_952: "Suspect de fraudă.",
      messageCode_998:
        "Tranzacția în rate nu este permisă cu acest card. Te rugăm să folosești un card de credit emise de Banca Transilvania.",
      messageCode_341016:
        "Autentificarea 3DS2 este refuzată de Authentication Response (ARes) - emitent",
      messageCode_341017:
        "Starea autentificării 3DS2 în ARes este necunoscută - emitent",
      messageCode_341018: "3DS2 CReq a fost anulat - client",
      messageCode_341019: "3DS2 CReq a eșuat - client/emitent",
      messageCode_341020: "3DS2 stare necunoscută în RReq - emitent",
    },
    paymentProcessorMaintenance:
      "Procesatorul de plăți este în mentenanță. Vă rugăm să încercați mai târziu.",
  },

  months: {
    january: "Ianuarie",
    february: "Februarie",
    march: "Martie",
    april: "Aprilie",
    may: "Mai",
    june: "Iunie",
    july: "Iulie",
    august: "August",
    september: "Septembrie",
    october: "Octombrie",
    november: "Noiembrie",
    december: "Decembrie",
  },

  passwordValidation: {
    uppercase: "Cel puțin o litera mare",
    lowercase: "Cel puțin o litera mică",
    number: "Cel putin o cifră",
    specialCharacter: "Cel puțin un simbol sau caracter special",
    passwordLength: "Cel puțin 8 caractere",
  },

  changePassword: {
    oldPassword: "Parola veche",
    newPassword: "Parola noua",
    confirmPassword: "Confiră noua parolă",
    oldPasswordRequired: "Parola veche este necesară",
    newPasswordRequired: "Parolă nouă este necesară",
    newPasswordLength: "Parola trebuie să aibă cel puțin 6 caractere",
    passwordMismatch: "Parolele trebuie să se potrivească",
    success: "Actualizare reușită!",
    errors: {
      invalidoldpassword: "Vechea parolă este greșită!",
    },
  },

  resetPassword: {
    passwordRecoveryPrompt: "Ți-ai uitat parola?",
    passwordRecoveryInstructions:
      "Vă rugăm să introduceți adresa de e-mail sau numărul de telefon asociat cu contul dvs. și vă vom trimite un cod pentru a vă reseta parola.",
    resetPasswordInstructions1Email:
      "Am trimis un cod de 6 cifre la adresa ta de email.",
    resetPasswordInstructions2Email:
      "Te rugăm să introduci codul in căsuța de mai jos pentru a-ți verifica emailul.",
    resetPasswordInstructions1Phone:
      "Am trimis un cod de 6 cifre la numărul tău de telefon.",
    resetPasswordInstructions2Phone:
      "Te rugăm să introduci codul in căsuța de mai jos pentru a-ți verifica numărul.",
    returnToSignIn: "Înapoi la autentificare",
    emailOrPhoneRequired: "Emailul sau telefonul este obligatoriu",
    emailOrPhone: "Email sau telefon",
    emailLabel: "Adresa de email",
    sendRequest: "Trimite cererea",
    passwordCharLimit: "Parola trebuie să aibă cel puțin 6 caractere",
    passwordRequired: "Parola este necesară",
    confirmPassRequired: "Confirmarea parolei este necesară",
    passMismatch: "Parolele trebuie să se potrivească",
    password: "Parola nouă",
    confirmPassword: "Confirmă parola nouă",
    changePasswordSuccess: "Schimbarea parolei a reușit!",
    updatePassword: "Actualizează parola",
    requestSentSuccessfully: "Cerere trimisă cu succes!",
    codeRequired: "Codul este necesar",
    errors: {
      invalidcode: "Codul este invalid!",
      usernotfound: "Nu s-a gasit niciun utilizator cu acea adresa de email!",
    },
  },

  productPage: {
    openNow: "Deschis acum",
    closedNow: "Închis acum",
    getTicketsAndTable: "Bilete & Mese",
    getTicket: "Cumpără Bilete",
    getTableAndMenu: "Mese & Meniu",
    getVoucher: "Cumpără Vouchere",
    back: "Înapoi",
    freeAccess: "ACCES GRATUIT",
    noTicket: "Nu aveți nevoie de bilet pentru a participa",
    noPublishedTickets: "BILETELE NU SUNT DE VANZARE INCA",
    comeBack: "Încercați mai târziu",
    dialogTitle: "Ai un bilet pentru un alt eveniment în coș",
    dialogAction: "Mergi la checkout",
    addToCartSuccesss: "Biletele au fost adăugate în coș cu succes!",
    comingSoon: "În curând",
    soldOut: "Vânzare încheiată",
    tickets: "Bilete",
    vouchers: "Vouchere",
    organizer: "Organizator",
    gallery: "Galerie",
    overview: "Descriere",
    priceRange: "Preț",
    viewMenu: "Deschide Meniul",
    viewTickets: "Vezi Biletele",
    viewBooking: "Vezi Rezervarea",
    reserveTable: "Rezervă Masă",
    dialogContent:
      "Dacă dorești să cumperi un bilet pentru acest eveniment, trebuie mai întâi să finalizezi procesul de checkout pentru cel care este deja în coș",
    typeOfTicket: "Tipul de bilet",
    onlyinApp: "disponibil doar în aplicație",
    redeem: "Cumpără în app",
    typeOfTicketError:
      "Problemă la validarea biletului! Te rog să reîncarci pagina.",
    addToCart: "Adaugă în Coş",
    update: "Actualizează",
    quantity: "Cantitate",
    buyNow: "Cumpără",
    addedToCart: "Adăugat în coș",
    chooseSeats: "Alege locurile",
    chooseTickets: "Inapoi la bilete",
    organizerEmail: "Email-ul organizatorului",
    organizerPhone: "Telefonul organizatorului",
    DatabaseError:
      "Problemă la comunicarea cu baza de date! Te rog să încerci mai târziu.",
    genericError: "A apărut o problemă! Te rog să încerci mai târziu.",
    continue: "Continuă",
    itemsInYourCartTitle: "Aveți deja produse în coș",
    itemsInYourCartDescription:
      "Dacă doriți să cumpărați produse pentru acest eveniment, trebuie să vă ștergem produsele din coș.",
    cancel: "Anulează",
  },

  verifyCode: {
    errorVerifying: "Eroare în timpul verificării!",
    successfulVerifying: "Verificare cu succes!",
    codeRequired: "Codul este necesar",
    verify: "Verifică",
  },

  landingPage: {
    title: "Ieșirea perfectă începe cu",
    subTitle1:
      "Nightz este o platformă de socializare dinamică și imersivă creată",
    subTitle2: "pentru a îmbunătăți planificarea iesirilor în oras.",
    events: "Postări",
    business: "Business",
    signIn: "Autentifică-te",
    signUp: "Înregistrează-te",
    home: "Acasă",
    allRightsReserved: "Toate drepturile rezervate",
    madeBy: "făcut de",
    logout: "Deconectează-te",
    logoutError: "A apărut o problemă! Te rugăm să încerci mai târziu.",
  },
  landingPageV2: {
    become_partner_button: "Să începem",
    become_partner_title: "DEVINO PARTENER",
    become_partner_description:
      "Ești proprietar de locație sau organizator de evenimente? Creează un cont de business și promovează-ți activitatea în câteva minute.",
    trending_carousel_title: "TRENDING",
    trending_carousel_description:
      "Descoperă cele mai bune evenimente și restaurante recomandate de Nightz.",
    trending_carousel_button: "Mai mult",
    friends_title: "PRIETENI",
    friends_description:
      "Nightz este cea mai rapidă și simplă cale de a ieși în oraș cu prietenii." +
      " Suntem prima rețea de socializarea pe bune, de aceea vrem să fii offline.",
    friends_button: "Hai pe Nightz",
    discover_title: "DESCOPERĂ",
    discover_description:
      "Descoperă cele mai cool evenimente, restaurante sau activități de făcut în weekend." +
      " Cu Nightz, întregul proces devine o experiență placută de socializare.",
    discover_button: "Explorează",
    chat_and_plan_title: "PLANIFICĂ",
    chat_and_plan_description:
      "Discută cu prietenii și planifică ieșiri împreună. " +
      "Începe planul cu o activitate sau creează un grup cu prietenii și decide mai târziu ce să faceți.",
    chat_and_plan_button: "Planifică",
    stay_in_loop_title: "RĂMÂI CONECTAT",
    stay_in_loop_description_mobile:
      "Ieșiri reușite, oriunde ai fi. Află cine vrea să iasă diseară în oraș și primește recomandări  în funcție de locație. Vrei să ieși singur? Ascunde-ți profilul ca să nu fii deranjat.",
    stay_in_loop_description_web:
      "Ieșiri reușite, oriunde ai fi. Află care dintre prietenii tăi vor să facă ceva diseară în orașul în care " +
      "te afli și primește recomandări de evenimente și " +
      "restaurante bazate pe locație. Vrei să ieși singur? Ascunde-ți profilul ca să nu fii deranjat.",
    stay_in_loop_button: "Ieși în oraș",
    faster_easier_title: "MAI RAPID, MAI UȘOR",
    faster_easier_description: "Decoperă activități, discută cu prietenii și cumpără bilete: fără link-uri în bio și site-uri externe. Tot ce ai nevoie, într-o singură aplicație, simplu și rapid. Pentru că Nightz e despre destinație, nu despre călătorie.",
    faster_easier_button: "Alătură-te acum",
    host_your_own_title: "ORGANIZEAZĂ",
    host_your_own_description: "N-ai chef să ieși în oraș? Adună-ți prietenii și planifică ceva chill: o seară de jocuri, o cabană, orice îți trece prin cap. Orice prieten se poate alătura planului, dar trebuie să îi accepți cererea inainte să vadă întreaga listă de participanți.",
    host_your_own_button: "Organizează"
  },
  businessPage: {
    showMore: "Show More",
    showLess: "Show Less",
    powerhouseTitle: "THE POWERHOUSE OF YOUR BUSINESS",
    powerhouseSubtitle: "We empower your restaurants and events with a seamless platform that fuels reservations and ticket sales, all while delivering the best experience to your clients.",
    powerhouseAction1: "Get started",
    powerhouseAction2: "Contact sales",
    innovateTitle: "INNOVATE YOUR BUSINESS",
    innovateSubtitle: "We don't follow trends. We create them.",
    innovateCardTitle1: "Table reservations for events",
    innovateCardSubtitle1: "No more Excel spreadsheets and phone calls and last minute no-shows. Now you can sell tables and promo packages ahead of the event.",
    innovateCardAction1: "Try now",
    innovateCardTitle2: "The first AI receptionist",
    innovateCardSubtitle2: "The AI employee that can take phone reservations and answer questions about your restaurant like a real person, even outside of business hours.",
    innovateCardAction2: "Call Allison",
    smartTicketingTitle: "SMARTER TICKETING, GREATER REACH",
    smartTicketingSubtitle: "Sell tickets with ease, grow your audience, and manage everything in one place.",
    smartTicketingAction: "Set up ticketing",
    fillTablesTitle: "FILL YOUR TABLES, STRESS-FREE",
    fillTablesSubtitle: "We automate reservations and give you the tools to manage guests and build loyalty effortlessly.",
    fillTablesAction: "Set up reservations",
    visibilityVelocityTitle: "TURN VISIBILITY INTO VELOCITY",
    visibilityVelocitySubtitle: "Nightz puts your business in front of the right audience — right when it matters.",
    visibilityVelocityAction: "Start analyzing",
    investmentTitle: "NIGHTZ IS NOT A COST IT'S AN INVESTMENT",
    investmentSubtitle: "Forget about subscriptions. Only pay when you sell. We thrive when your business thrives, that's why we want to help you sell like crazy.",
    investmentAction: "Start selling like crazy",
    investmentCardTitle1: "TICKETING",
    investmentCardValue1: "5% of all online ticket sales",
    investmentCardSubtitle1: "decreases with volume",
    investmentCardTitle2: "RESERVATIONS",
    investmentCardValue2: "5% of spending for our leads",
    investmentCardSubtitle2: "2.5% for your returning clients",
    pricesStartAt: "prices start at",
    ticketingSection: {
      tableTitle: "Ticketing Features",
      ticketingFeatures: {
        feature1: "Custom non-branded event page",
        description1: "Nightz is all about your event. That's why we keep our branding to a minimum.",
        feature2: "Unlimited events",
        description2: "Manage unlimited events from the same account.",
        feature3: "Unlimited tickets categories",
        description3: "Each category has its own unique serial number.",
        feature4: "Custom ticket design",
        description4: "Set custom colors for your ticket design.",
        feature5: "Early bird sales system",
        description5: "Generate ticket categories with increasing prices. Once the cheaper categories are sold out, the expensive ones get unlocked, one at a time.",
        feature6: "Group promotions",
        description6: "Nightz' innovation: Bulk discount that collects the data of all promotion participants, increasing the marketing userbase by 30% for each event.",
        feature7: "App promotions",
        description7: "Set lower ticketing prices in the mobile app, to build more loyalty for your audience with custom push notifications, ticket invites and discounts.",
        feature8: "Discount codes",
        description8: "Create discount codes for your loyal clients or in partnership with influencers.",
        feature9: "Ticket invites",
        description9: "Send ticket invites on email and in app to loyal customers. When they get invited, all their friends will be notified, resulting in more organic promotion for your business.",
        feature10: "Seating floorplans",
        description10: "Theaters, cinemas, conference rooms - we build any custom floorplan if your event requires seat booking.",
        feature11: "Live sales statistics",
        description11: "Start measuring revenue and identifying buyer trends as soon as your event is published.",
        feature12: "Live marketing statistics",
        description12: "Identify main lead generation channels and analyze the ROI of your marketing campaigns using custom links.",
        feature13: "Scanner in mobile app",
        description13: "Scan tickets directly from the mobile app, using an unlimited number of devices.",
        feature14: "Automated tax collection and payment",
        description14: "If enabled, this option allows us to take care of the entire govt. bureaucracy and taxes in different markets across Europe. You can sell stress free.",
        feature15: "Automated weekly payouts",
        description15: "Payouts happens every Monday, but earlier payouts are available for a flat fee of $5 per transaction.",
        feature16: "Multiple employee accounts",
        description16: "Create and control all your employees accounts in one place. Their only available features are to scan tickets and manage reservations. Restrict them once they no longer need access.",
      }
    },
    reservationsSection: {
      tableTitle: "Reservation features",
      reservationsFeatures: {
        feature1: "Custom non-branded venue page",
        description1: "Nightz is all about your restaurant. That's why we keep our branding to a minimum.",
        feature2: "Unlimited venues",
        description2: "Manage unlimited venues from the same account.",
        feature3: "Booking widget in your own website",
        description3: "Implement our booking widget with a low-code solution on any website in less than 5 minutes.",
        feature4: "Live 24/7 bookings",
        description4: "Get bookings anytime, even outside of opening hours.",
        feature5: "Live master calendar",
        description5: "View, add and modify all bookings in a single place. All changes appear instantly.",
        feature6: "Automated or manual confirmation",
        description6: "Enable automated confirmation to make the reservations process instantaneous for customers or keep manual confirmation for more control.",
        feature7: "Instant notifications",
        description7: "On Email/SMS and in the mobile app for reservation requests and updates.",
        feature8: "Smart assign",
        description8: "Automatically assign reservations to tables to maximize venue occupancy. Never overbook.",
        feature9: "Mobile/tablet app for reservations management",
        description9: "Use the mobile app to receive push notifications when reservations are added or cancelled. Manage all your bookings from the master calendar from an unlimited number of devices.",
        feature10: "Guest fidelity",
        description10: "Get informed on user loyalty based on the number of bookings created by a customer.",
        feature11: "Custom booking setup",
        description11: "Define custom opening hours, minimum and maximum booking lengths.",
        feature12: "Custom zone setup",
        description12: "Define separate bookable zones and tables in your venue (ex: rooftop, garden, saloon etc.)",
        feature13: "Custom table setup",
        description13: "Define individual tables to match your venue's layout. Set custom capacity and booking deposit.",
        feature14: "Booking deposits",
        description14: "Set a booking deposit to eliminate no-shows and increase profitability. Set the deposit at venue-level or choose custom prices depending on the zone or table size.",
        feature15: "Menu pre-order",
        description15: "Enable free or paid menu ore-orders, to make the dining process faster and seamless for customers and improve your cash flow. Automated payouts are made daily.",
        feature16: "Order management",
        description16: "View all items pre-ordered by users.",
        feature17: "Live reservation statistics",
        description17: "Identify reservation trends for the current day, week or month, to identify opportunities to improve your restaurant's occupancy.",
        feature18: "Live marketing statistics",
        description18: "Identify main lead generation channels and analyze the ROI of your marketing campaigns using custom links.",
        feature19: "POS Integration",
        description19: "Track user spending, identify high value clients and optimize table profitability. ",
        feature20: "Multiple employee accounts",
        description20: "Create and control all your employees accounts in one place. Their only available features are to scan tickets and manage reservations. Restrict them once they no longer need access.",
        feature21: "Real-time guest spend alerts",
        description21: "Get notified when a high-value guest spends over a certain threshold with your venue. Use this data to show appreciation, give out promotions and build up customer loyalty.",
        feature22: "Filtered reviews",
        description22: "We hate fake reviews as much as you do. That's why we set a minimum table spend consumers have to reach before they can leave a review.",
        feature23: "Guest tags",
        description23: "Assign tags to guests to identify VIP clients, regulars, good and bad behaving customers, as well as allergies or preferences.",
        feature24: "Reservation waitlist",
        description24: "Waitlists allow customers to line up for a table when your venue is full. They get notified via SMS/Email or app as soon as a table becomes available again.",
        feature25: "Customizable floor plans",
        description25: "We help you build floorplans according to your venue's setup, in order to view, manage, merge and assign reservations to tables easier.",
        feature26: "The AI Receptionist",
        description26: "Forget about phone calls, our AI employee can take phone reservations and answer questions about your restaurant or current promotions like a real person, even outside of business hours. You can customize everything from the name to the voice of your new employee. ",
      }
    },
    marketingSection: {
      tableTitle: "Marketing features",
      marketingFeatures: {
        feature1: "Free publishing on website and app",
        description1: "Nightz is designed for all savvy urban enthusiasts out there, creating a great opportunity to promote your business to a new audience of high-value customers.",
        feature2: "Regional/national promotion",
        description2: "Expand your visibility to nearby regions or nationwide with Nightz's trending category, to gain new clients. For best results, the minimum promotion duration is a week and the pricing depends on the number of regions covered.",
        feature3: "Performance-based promotion",
        description3: "Well-performing businesses are automatically pushed in the top 10 experiences in every city. The rank is updated weekly, based on the number of tickets and reservations booked.",
        feature4: "Sales channels insights",
        description4: "Identify the most profitable lead generation channels that bring the most high-value clients. Compare this data against your competitors to identify areas of improvement.",
        feature5: "Demographic insights",
        description5: "We use advances analytics tools, compliant with the latest GDPR policies, in order to consolidate the demographics of your audience. This can help you better understand who is your main audience, identify underserved audiences and create better business strategies.",
        feature19: "Custom campaigns",
        description19: "Send app notifications, email campaigns or SMS blasts to your customers to keep them engaged with your business. Fees vary depending on campaign type and volumes.",
      }
    }
  },

  categories: {
    event: "Eveniment",
    theatre: "Teatru",
    experience: "Experiență",
    restaurant: "Restaurant",
    custom: "Custom",
    chill: "Chill",
    conference: "Conferință",
    sportEvent: "Eveniment Sportiv",
    landmark: "Monument",
    bar: "Bar",
    coffeeShop: "Cafenea",
    headingSubcategoryNightz: "Alege una dintre opțiunile Nightz",
    headingSubcategoryDayz: "Alege una dintre opțiunile Dayz",
    headingCategory: "În ce categorie se potrivește activitatea ta?",
    backToMyListings: "Înapoi la Postările Mele",
  },

  platformSettings: {
    title: "Setările Platformei",
    subtitle: "Interfaţă administrator",
  },

  companiesList: {
    title: "Lista companiilor",
    subtitle: "Interfaţă administrator",
    view: "Vizualizează",
    edit: "Switch pentru editarea câmpurilor",
    save: "Salvează",
    download: "Descarcă tabelul",
    searchNamePlaceholder: "Caută după nume, telefon sau email...",
    tableHeader: {
      name: "Nume",
      emailAccount: "Email Cont",
      phoneAccount: "Număr Telefon Cont",
      company: "Companie",
      cui: "CUI",
      cuiPopover: "Codul Unic de Înregistrare",
      documents: "Documente",
      status: "Status",
    },
    statusTypes: {
      pending: "În curs de verificare",
      banned: "Respins",
      approved: "Acceptat",
      selfManaged: "Verificare proprie",
      draft: "Schiţă",
    },
    tableCellPopover: {
      all: "Toate documentele încărcate",
      mandatory: "Certificatul de înregistrare si Contractul b2b încărcate",
      notMandatory:
        "Certificatul de înregistrare si Contractul b2b NU au fost încă încărcate",
    },
    registerForm: {
      title: "Aprobare cont companie",
      description:
        "Verifică toate informaţiile companiei şi setează comisionul de ticketing conform contractului.",
      status: "Satus companie",
      commission: "Comision tranzacţii",
    },
    errorMessages: {
      required: "Acest camp este obligatoriu",
      min: "Comisionul trebuie sa fie mai mare sau egal cu 0",
      max: "Comisionul trebuie sa fie mai mic sau egal cu 100",
    },
  },
  eventTicketsTable: {
    status: {
      ISSUED: "EMIS",
      SCANNED: "SCANAT",
      REFUNDED: "RAMBURSAT"
    },
    ticketSeries: "Seria biletului",
    orderID: " ID Comandă",
    holderName: "Nume",
    ticketsType: "Tipul de bilet",
    ticketPrice: "Prețul biletului",
    ticketStatus: "Statusul biletului",
    phone: "Număr de telefon",
    email: "email",
    itemId:"ID produs",
    itemName:"Nume produs",
    itemQuantity:"Cantitate",
    itemUnitPrice:"Preț per unitate (lei)",
    title:"Produse"
  },

  imageGallery: {
    title: "Trage sau Selectează un fișier",
    drop: "Trage fișierele aici sau apasă pe ",
    browse: " navighează ",
    message: " prin calculatorul tău.",
  },

  transactionTab: {
    excelOptions: {
      ticketsOnly: "Doar Bilete",
      ticketsOnlyFiltered: "Doar Bilete Filtrare",
      ordersOnly: "Doar Tranzacții",
      ordersOnlyFiltered: "Doar Tranzacțiile Filtrare",
      ticketsAndOrders: "Tranzacții și Bilete",
      ticketsAndOrdersFiltered: "Tranzacții și Bilete Filtrare",
    },
  },

  eventManager: {
    areas: "Zone",
    tables: "Mese",
    addModule: "Adaugă Modul",
    statusTypes: {
      created: "Creat",
      draft: "Schiță",
      ready: "Pregătit",
      pending: "În curs de verificare",
      ongoing: "În progres",
      expired: "Expirat",
      deleted: "Șters",
      cancelled: "Anulat",
      waitingForPayment: "În curs de plată",
    },
    title: {
      newEvent: "Creează o Postare",
      editEvent: "Editează",
    },
    notApprovedDurationTitle: "Sunt necesare acțiuni suplimentare:",
    notApprovedDurationBody:
      "Pentru a schimba data, trebuie să ștergi mai întâi rezervările actuale.",
    notApprovedDurationButton: "OK",
    modulesSummary: {
      endTimeError: "End time is earlier than start time",
      settingsTitle: "Setări de Administrare",
      settingsDescription:
        "Controlează caracteristicile principale ale evenimentului, precum categoria, tag-urile, statutul și vizibilitatea.",
      durationTitle: "Durată",
      durationDescription:
        "Spuneți oamenilor când începe și se termină evenimentul dumneavoastră sau dacă serviciile dumneavoastră sunt disponibile permanent.",
      generalDetailsTitle: "Detalii Generale",
      generalDetailsDescription:
        "Denumiți evenimentul sau locația, setați imaginea principală de copertă și spuneți o poveste audienței dumneavoastră.",
      locationTitle: "Locație",
      locationDescription:
        "Informați oamenii despre locația evenimentului și fiți descoperiți de persoanele din zonă.",
      ratingTitle: "Rating",
      ratingDescription:
        "Configurați alte linkuri asociate cu listarea dumneavoastră.",
      contactTitle: "Detalii de Contact",
      contactDescription:
        "Configurați linkurile dumneavoastră sociale precum și metodele prin care oamenii pot lua legătura cu dumneavoastră.",
      imageGalleryTitle: "Galerie de Imagini",
      imageGalleryDescription:
        "Creează un impact vizual adăugând imagini impresionante ale produselor sau serviciilor dumneavoastră.",
      ticketingTitle: "Bilete",
      ticketingDescription:
        "Configurați bilete personalizate gratuite sau cu plată, adăugați coduri promoționale și trimiteți invitații pentru evenimentul dumneavoastră.",
      openingHoursTitle: "Program de funcționare",
      openingHoursDescription:
        "Informați clienții despre ora de deschidere și de închidere.",
      reservationsTitle: "Rezervări",
      reservationsDescription:
        "Definiți spații rezervabile în cadrul locației dumneavoastră și începeți să gestionați rezervările instantaneu.",
      reservationsMenuTitle: "Rezervări cu meniu",
      reservationsMenuDescription: "Definiți meniuri rezervabile.",
    },
    warningDeleteModal: {
      standardWarningDeleteTitle: "Ștergere Modul",
      standardWarningDeleteMessage:
        "Doriți ca acest modul să fie șters și toate datele asociate cu acesta să fie de asemenea și ele șterse?",
    },
    nameLabel: "Nume",
    tableTitle: "Zone disponibile",
    priceLabel: "Preț",
    addArea: "Adaugă zonă",
    editOption: "Editează",
    deleteOption: "Șterge",
    editModalNewAreaTitle: "Adaugă zonă nouă",
    editModalExistingAreaTitle: "Editează zona",
    editModalNameLabel: "Numele zonei",
    editModalPriceLabel: "Prețul mesei",
    forbiddenDeleteAreaDialogTitle: "Eroare",
    forbiddenDeleteAreaDialogDescription:
      "Zona acesta nu poate fi ștearsă deoarece există mese care aparțin de ea." +
      " Șterge mai întâi mesele respective și după vei putea șterge zona aceasta",
    noAreaTitle: "Modulul de rezervări este incomplet!",
    noAreaBody:
      "Te rugăm să adaugi zone și mese sau să elimini modulul pentru a publica activitatea.",
    noAreaButton: "Ok",
    spaces: {
      nameLabel: "Nume",
      areaLabel: "Zonă",
      capacityLabel: "Capacitate",
      priceLabel: "Preț",
      tableTitle: "Mese disponibile",
      addSpace: "Adaugă massă",
      editOption: "Editează",
      deleteOption: "Șterge",
      editModalNewSpaceTitle: "Adaugă o masă nouă",
      editModalExistingSpaceTitle: "Editează masa",
      editModalNameLabel: "Numele mesei",
      editModalCapacityLabel: "Capacitatea mesei",
      editModalPriceLabel: "Prețul mesei",
      noAreasModalTitle: "Nu a fost găsită nicio zonă",
      noAreasModalDescription:
        "Nu a fost găsite nicio zonă din care masa să facă parte. Te rugăm să adaugi mai întâi o zonă.",
    },
    menus: {
      tableTitle: "Meniuri",
      nameLabel: "Nume",
      categoryLabel: "Categorie",
      priceLabel: "Preț",
      quantityLabel: "Cantitate",
      addMenu: "Adaugă meniu",
      editOption: "Editează",
      deleteOption: "Șterge",
      editModalNewMenuTitle: "Adaugă un meniu nou",
      editModalExistingMenuTitle: "Editează meniul",
      editModalNameLabel: "Nume",
      editModalCategoryLabel: "Categorie",
      editModalPriceLabel: "Preț",
      editModalQuantityLabel: "Cantitate",
      editModalMetricLabel: "Unitate de masură",
      category: {
        APPETIZERS: "Aperitive",
        BREAKFAST: "Mic Dejun",
        SPECIALS: "Specialități",
        SOUPS: "Supe",
        SALADS: "Salate",
        MAIN_COURSE: "Fel Principal",
        SIDES: "Garnituri",
        PASTA_RISOTTO: "Paste & Risotto",
        PIZZA: "Pizza",
        FLATBREAD: "Lipii",
        BURGERS: "Burgeri",
        FISH: "Pește",
        SEAFOOD: "Fructe de Mare",
        GRILL: "Grătar",
        CHICKEN: "Specialități din Pui",
        PORK: "Specialități din Porc",
        BEEF: "Specialități din Vită",
        PLATTER: "Platouri",
        VEGETARIAN_VEGAN: "Opțiuni Vegetariene / Vegane",
        SNACKS: "Snacks",
        SAUCES: "Sosuri",
        DESSERTS: "Deserturi",
        PROMOTIONAL_PACKAGES: "Pachete Promoționale",
        SOFT_DRINKS: "Băuturi Răcoritoare",
        JUICES_SMOOTHIES: "Sucuri & Smoothies",
        TEA_COFFEE: "Ceai & Cafea",
        BEER: "Bere",
        ALCOHOLIC_COCKTAIL: "Cocktails",
        NON_ALCOHOLIC_COCKTAIL: "Cocktails fără Alcool",
        WINES: "Vinuri",
        WINES_GLASSES: "Vinuri la Pahar",
        WINE_BOTTLES: "Vinuri la Sticlă",
        SPARKLING_WINES: "Vinuri Spumante",
        VODKA: "Vodcă",
        TEQUILA: "Tequila",
        GIN: "Gin",
        WHISKEY: "Whiskey",
        COGNAC: "Coniac",
        LIQUOUR: "Lichior",
        PALINCA: "Pălincă",
        SHOTS: "Shots",
        CIGARETTES: "Țigări",
      },
      quantityMetric: {
        GRAM: "g", 
        KILOGRAM: "kg",
        MILLILITER: "ml",
        LITER: "l",
        PIECE: "buc",
        SLICE: "felie",
        CUP: "cupă",
        BOWL: "bol",
        PLATE: "farfurie",
        BOTTLE: "sticlă",
        GLASS: "pahar",
        SHOT: "shot",
        PINT: "halbă",
        HALF_PINT: "1/2 halbă"
      },
    },
    bookingSettings: {
      cardLabel: "Setări pentru rezervări ",
      bookingIntervalLabel: "Interval rezervări",
      bookingDurationLabel:
        "Duratelele posibile ale unei rezervări (în minute)",
      automaticConfirmationLabel: "Confirmare automată a unei rezervări",
      holdingFeeLabel: "Taxă pentru crearea unei rezervări",
      bookingIntervalPart1: "În fiecare",
      bookingIntervalPart2: "de minute",
    },
    embedIframe: {
      title: "Embed Iframe",
      descriptionPart1:
        "Pentru a implementa sistemul de creare de rezervari in site-ul dumneavoastra, puteti sa folositi generatorul de mai jos, care va creea un Iframe cu valorile de width si height specificate. In cazul in care aveti nevoie de asistenta, nu ezitati sa ne ",
      descriptionPart2: "contactati.",
      width: "Width (px)",
      height: "Height (px)",
      submit: "Generate",
      copyText: "Copy code",
      copySuccess: "Iframe copied to clipboard",
      copyFailure: "Failed to copy iframe to clipboard",
      theme: "Tema",
      darkLabel: "Întunecat",
      lightLabel: "Luminos",
    },
    closeCurrentDay: "Deschis acum!   Închide accesul pentru această zi",
    openCurrentDay: "Închis acum!   Deschide accesul pentru această zi",
    toggleModuleTrue: "ACTIVAT",
    toggleModuleFalse: "DEZACTIVAT",
    listings: "Postările mele",
    dashboard: "Postările mele",
    business: "Afacerea mea",
    delete: "Şterge",
    deleteMessage: "Eşti sigur că vrei să ştergi",
    items: "elemente",
    item: "element",
    edit: "Editează",
    duplicate: "Duplicare",
    selected: "selectate",
    new: "Postare nouă",
    clear: "Şterge selecția",
    entryType: "Tipul de intrare",
    free: "Gratis",
    ticket: "Bilet",
    ditlReport: "Raportare DITL",
    ditlReportQuestion: "Doriți raportare la DITL?",
    yes: "Doresc",
    no: "Nu doresc",
    subcategory: "Subcategorie",
    subcategories: {
      Event: "Eveniment",
      Restaurant: "Restaurant",
      CoffeeShop: "Cafenea",
      Experience: "Experiență",
      Landmark: "Monument",
      Chill: "Relaxare",
      Bar: "Bar",
    },
    bookLink: "Link de rezervare",
    menuLink: "Link de meniu",
    noVisibleticketsError: "Cel puțin un bilet trebuie să fie vizibil!",
    eventName: "Numele Postării",
    description: "Descriere",
    writeSomethingAwesome: "Scrie ceva interesant...",
    nightzComission: "Comisionul Nightz",
    nightzComissionText:
      "Comisionul pe care Nightz îl va lua de pe urma evenimentului",
    mainImage: "Imaginea principală a postării",
    mainImageMobile: "Imagine Trending Fundal Mobile",
    mainImageWeb: "Imagine Trending Fundal Web",
    images: "Imagini",
    useImagesModuleEnable: "Adăugare mai multe imagini postării tale",
    useImagesModuleDisable:
      "Dezactivează opțiunea de a avea mai multe imagini postării. ",
    createOpeningHoursModuleTitle: "Adăugare Modul de Opening Hours",
    createOpeningHoursModuleDescription:
      "Doriți crearea unui modul special pentru Opening Hours prin care să personalizați programul în cadrul listării dumneavoastră?",
    deleteOpeningHoursModuleTitle: "Ștergere Modul de Opening Hours",
    deleteOpeningHoursModuleDescription:
      "Doriți ștergerea modului de Opening Hours?",
    createReservationsModuleTitle: "Adăugare Modul de Rezervări",
    createReservationsModuleDescription:
      "Doriți crearea unui modul special pentru Rezervări prin care veți putea manageria rezervările?",
    deleteReservationsModuleTitle: "Ștergere Modul de Rezervări",
    deleteReservationsModuleDescription:
      "Doriți ștergerea modului de rezervări? Dacă vei continua, toate datele asociate acestui modul vor fi șterse definitiv.",
    useOpeningHoursModuleEnable:
      "Adăugă modulul de opening hours. Acesta îți permite să configurezi pentru fiecare zi " +
      "intervalul de ore în care listarea ta e deschisă pentru vizitare",
    useOpeningHoursModuleDisable: "Dezactivează modulul de opening hours.",
    useReservationsModuleEnable:
      "Adăugă modulul de rezervări. Acesta îți permite primești rezervări în cadrul locației tale.",
    useReservationsModuleDisable: "Dezactivează modulul de rezervări.",

    createReservationsMenuModuleTitle: "Adăugare Modul de Rezervări cu meniu",
    createReservationsMenuModuleDescription:
      "Doriți crearea unui modul special pentru Rezervări cu meniu prin care veți putea manageria rezervările cu meniu?",
    deleteReservationsMenuModuleTitle: "Ștergere Modul de Rezervări cu meniu",
    deleteReservationsMenuModuleDescription:
      "Doriți ștergerea modului de Rezervări cu meniu.",
    useReservationsMenuModuleEnable:
      "Adăugă modulul de rezervări cu meniu. Acesta îți permite primești rezervări cu meniu în cadrul locației tale. Necesită modulul de rezervări.",
    useReservationsMenuModuleDisable:
      "Dezactivează modulul de rezervări cu meniu.",

    useTicketsModuleEnable: "Adăugă modulul de bilete postării tale",
    useTicketsModuleDisable: "Dezactivează modulul de bilete postării tale. ",
    forbiddenDeleteTicketsModuleModalTitle: "Ștergerea Modulului de Bilete",
    forbiddenDeleteTicketsModuleModalDesc:
      "Acest modul nu poate fi șters deoarece vânzarea biletelor a început deja. " +
      "Pentru a șterge modulul contactează un Account Manager, deoarece este necesară " +
      "rambursarea contravalorii biletelor cumpărate.",
    allowedDeleteTicketsModuleModalDesc:
      "Ștergerea acestui modul cauzează ștergerea tuturor categoriilor de bilete create." +
      " Continui ștergerea modulului?",
    normalTickets: "Bilete normale",
    vouchers: "Vouchere",
    earlyBirdTickets: "Bilete early bird",
    name: "Nume",
    percent: "Procent",
    promoCodes: "Coduri de promoție",
    serialNumber: "Număr de serie",
    initialTickets: "Bilete inițiale",
    remainingTickets: "Bilete rămase",
    ticketsModule: "Modulul de Bilete",
    displayedPrice: "Preț afisat",
    addTicket: "Adaugă bilet",
    addVoucher: "Adaugă voucher",
    eventKey: "Cheie eveniment SeatsIo",
    editTicket: {
      ticketName: "Numele biletului",
      voucherName: "Numele voucherului",
      numberOfAvailableTickets: "Numărul de bilete disponibile",
      numberOfAvailableVouchers: "Numărul de vouchere disponibile",
      priceReportDITL: "Prețul raportat la DITL (lei)",
      priceCashout: "Sumă încasată dorită (lei)",
      displayedPrice: "Prețul afișat (lei)",
      commisionNightz: "Comisionul Nightz (lei)",
      commisionDitl: "Impozit Ditl (lei)",
      valueNightzTva: "Valoare TVA Nightz (lei)",
      valueClientTva: "Valoare TVA Companie (lei)",
      invoiceToNightz: "Valoare facturată către Nightz (lei)",
      ticketDescription: "Descrierea biletului",
      addTicket: "Adaugă bilet",
      addVoucher: "Adaugă voucher",
      saveTicket: "Salvează",
      participants: "Participanți",
      percent: "Procent",
      discountPerPerson: "Discount pe persoană (%)",
      price: "Preț",
      ticketPromotionType: "Tip promoție",
      noPromotion: "Fără promoție",
      appOnlyPromotion: "Doar în aplicație",
      tierPromotion: "Promoție de tip tiered",
      normalPrice: "Preț normal",
      editTicket: "Editează biletul",
      addNewTicket: "Adaugă bilet nou",
      cancel: "Anulează",
      errors: {
        ticketNumberRequired: "Numărul de bilete este necesar",
        ticketNumberMin: "Numărul de bilete trebuie să fie mai mare decât 0",
        ticketNumberInteger: "Numărul de bilete trebuie să fie un număr întreg",
        ticketPriceRequired: "Prețul pe bilet este necesar",
        ticketPriceDitlRequired: "Prețul pe bilet este necesar",
        ticketNameRequired: "Numele biletului este necesar",
        ticketValueNumber: "Prețul biletului trebuie să fie un număr",
        ticketPromotionPriceLimit:
          "Prețul trebuie să fie mai mic decât cel anterior.",
        ticketPromotionParticipantLimit:
          "Numărul de participanți trebuie să fie mai mare decât cel anterior.",
        ticketPromotionPriceLimit:
          "Prețul trebuie să fie mai mic decât cel al nivelului anterior",
        ticketPromoPriceRequired: "Prețul promoțional este obligatoriu",
        maxPercentage: "Procentajul nu poate fi mai mare de 100%",
        nrPercentageRequired: "Procentajul este obligatoriu",
        minNrPercentage:
          "Numărul minim de procentaj trebuie să fie mai mare de 1",
        maxNrPercentage: "Numărul trebuie să fie mai mic de 100.",
      },
    },
    editPromoCode: {
      addNewPromoCode: "Adaugă un nou cod de promoție",
      editPromoCode: "Editează codul de promoție",
      name: "Code de Promoție",
      percent: "Discount Procentual",
      addPromoCode: "Adaugă Cod de Promoție",
    },
    invite: {
      send: "Trimite",
      title: "Invită-ți prietenii",
      name: "Nume complet",
      email: "Adresa de email",
      quantity: "Numărul de bilete",
      success: "Invitatiile au fost trimise cu succes",
      validation: {
        quantityRequired: "Cantitatea de bilete este necesară",
        quantityAvailable: "Cantitate invalida.",
        quantityDigits: "Cantitatea de bilete trebuie sa contina doar cifre",
        nameRequired: "Numele complet este necesar",
      },
    },
    noTicket: "Niciun bilet emis în prezent.",
    category: "Categorie",
    insights: "Detalii",
    typesOfMusic: "Tipuri de muzică",
    dateAndTimeInfo: "Informații despre dată si ora",
    opening: "Deschidere",
    closing: "Închidere",
    startDate: "Ora de început a evenimentului",
    endDate: "Ora de sfârșit a evenimentului",
    contactInfo: "Informații de contact",
    venueInfo: "Informații despre restaurant",
    facebook: "Profilul tău de facebook",
    instagram: "Profilul tău de instagram",
    tiktok: "Profilul tău de tiktok",
    organizerWebsite: "Link-ul către website-ul tău",
    organizerEmail: "Adresa de email a organizatorului",
    venueEmail: "Adresa de email",
    organizerPhone: "Numărul de telefon al organizatorului",
    organizerDisplayName: "Numele afișat al organizatorului",
    tags: "Tags",
    ticketTypes: "Bilete",
    legalName: "Numele legal al organizatorului",
    cuiOrganizer: "CUI-ul organizatorului",
    street: "Strada și numărul",
    city: "Orașul",
    county: "Județul",
    venueName: "Numele locației",
    displayName: "Numele afișat al organizatorului",
    isMainEvent: "Este Main Event?",
    isPermanent: "Detalii despre desfășurare",
    isPermanentTrue: "Deschis Permanent",
    isPermanentFalse: "Eveniment unic",
    mainEventCountyVisibility: "Județul în care este Main Event",
    status: "Status",
    addressLabel: "Adresa",
    visibility: "Vizibilitatea evenimentului",
    countyVisibility: "Vizibilitatea evenimentului pe judete",
    googleLocationLink: "Link de Google Maps pentru locație",
    eventTickets: "Biletele evenimentului",
    displayedNameOfLocation: "Numele afișat al locației",
    downloadData: "Descarcă datele",
    createData: "Creează produsul",
    saveChanges: "Salvează modificările",
    publishNow: "Publică acum",
    publishing: "Publicare",
    isPublishedTrue: "Publicat",
    isPublishedFalse: "Nepublicat",
    pendingButton: "Așteaptă verificarea",
    unPublish: "Anulează publicarea acum",
    noTicketsSold: {
      title: "Nu s-au vandut bilete",
      content:
        "Deoarece nu s-au vândut bilete pentru acest eveniment, nu există date disponibile pentru a fi descărcate.",
      understand: "Am ințeles",
    },
    visibilityOptions: {
      web: "Doar web",
      app: "Doar app",
      web_and_app: "Pe web și app",
    },
    yourEvents: "Postările tale",
    allOfYourEvents: "Toate postările tale",
    addAPartyNow: "Adaugă o postare acum",
    borderColorTicket: "Culoarea marginii unui bilet",
    trendingBg: "Culoare Trending",
    borderColorVoucher: "Culoarea marginii unui voucher",
    tabs: {
      eventDetails: "Detalii postare",
      transactions: "Tranzacții",
      taxReports: "Rapoarte fiscale",
      statistics: "Statisticii",
      reservationsCalendar: "Calendar rezervări",
      reservationsTable: "Tabel rezervări",
      tickets: "Bilete",
    },
    companyNotApprovedTitle: "Te rugăm să îți verifici compania",
    companyNotApproved:
      "Compania ta nu este încă verificată. Te rugăm să introduci datele companiei și să le trimiți pentru aprobare. După verficarea companiei, poți începe să publici.",
    companyPendingTitle: "Detaliile companiei tale sunt în proces de verificare",
    companyPending:
      "Compania ta nu este încă verificată. Te rugăm să aștepți pentru a se finaliza verificarea.",
    cancel: "Anulează",
    rating: "Rating",
    reviewsCount: "Numărul de recenzii",
    priceRangeStart: "Prețul minim",
    priceRangeEnd: "Prețul maxim",
    program: "Programul",
    eventEditor: {
      publishDialogTitle: "Ești sigur că vrei să publici evenimentiul?",
      publishDialogContent:
        "Nu vei mai putea modifica biletele după această acțiune. Asigură-te că ai raportat biletele la DITL. Poți găsi formularul DITL",
      publishDialogLink: "aici",
      publishDialogAgreeButton: "Publică",
      publishDialogDisagreeButton: "Renunță",
    },
    strechToSeeMoreColumns:
      "Puteti sa vedeti mai multe coloane daca folosit proprietatea de strech din setari",
  },

  deleteEvent: {
    errorName: "Nu ați introdus corect numele postării",
    dialogTitle: "Ștergeți această postare?",
    dialogSubtitle: "Confirmați ștergerea introducând",
    dialogSubtitleVariant1: "numele postării",
    dialogSubtitleVariant2: "următorul cuvânt",
    dialogSubtitleVariant3:
      "Acest eveniment nu poate fi șters pentru că au fost vândute bilete. " +
      "Dacă doriți ca acest eveniment să nu mai poată fi accesat de către utilizatori vă rugăm să îi dați unpublish " +
      "și să contactați administratorul în caz că aveți nevoie de ajutor suplimentar.",
  },
  duplicateListing: {
    dialogTitle: "Doriți duplicarea aceastei postări?",
    dialogSubtitle:
      "Se va creea o nouă postare cu status draft utilizând datele acestei postări",
  },

  staticPages: {
    contactUs: {
      title: "Contactează-ne",
      pageTitle: "Date de contact",
      workProgram: "Program de lucru:",
      workProgramDetails:
        "Luni – Vineri: 09:00 – 17:00 Weekend-uri și sărbători legale: Închis",
      address: "Adresă (pentru corespondență):",
      addressDetails:
        "Sat Remetea Chioarului, Comuna Remetea Chioarului, Nr. 279, Județ Maramureș; Cod postal: 437230",
      phone: "Telefon",
    },
    cookiesPolicy: {
      title: "Politica de cookies",
    },
    termsAndConditions: {
      title: "Termeni și condiții",
    },
    privacyPolicy: {
      title: "Politica de confidențialitate",
    },
  },

  messages: {
    warnUserToSaveChanges:
      "Pentru ca poza să fie salvată te rugăm să apeși pe butonul de 'Salvează modificările'.",
    moduleCreatedSuccessfully: "Modul Creat cu Succes.",
    moduleCreatedError: "Eroare la crearea modulului.",
    moduleDeletedSuccessfully: "Modul șters cu succes.",
    moduleDeletedError:
      "Eroare la ștergerea modulului. Vă rugăm să ștergți înainte toate datele care țin de modul.",
    areaCreateSuccessfully: "Zona a fost creată cu succes",
    areaCreateError: "Eroare la crearea zonei",
    areaUpdatedSuccessfully: "Zona a fost actualizată cu succes",
    areaUpdateError: "Eroare la actualizarea zonei.",
    areaDeleteSuccessfully: "Zona a fost ștearsă cu succes",
    areaDeleteError: "Eroare la ștergerea zonei.",
    spaceDeleteSuccessfully: "Masa a fost ștearsă cu succes",
    spaceDeleteError: "Eroare la ștergerea mesei.",
    spaceCreateSuccessfully: "Masa a fost creata cu succes",
    spaceCreateError: "Eroare la crearea mesei.",
    spaceUpdateSuccessfully: "Masa a fost actualizată cu succes.",
    spaceUpdateError: "Eroare la actualizarea mesei",
    menuDeleteSuccessfully: "Meniul a fost ștears cu succes",
    menuDeleteError: "Eroare la ștergerea meniului.",
    menuCreateSuccessfully: "Meniul a fost creat cu succes",
    menuCreateError: "Eroare la crearea meniului.",
    menuUpdateSuccessfully: "Meniul a fost actualizat cu succes.",
    menuUpdateError: "Eroare la actualizarea meniului",
  },
  companyRegisterForm: {
    title: "Completează formularul pentru a-ți crea contul",
    skipForNow: "Trimite mai târziu",
    saveChangesAndCompleteLater:
      "Salvează și trimite mai târziu",
    saveChanges: "Salvează modificările",
    modifyNow: "Modifică acum",
    messageModalHasErrors:
      "Informațiile au fost salvate, dar formularul nu a fost trimis deoarece unele câmpuri sunt incomplete sau incorecte și necesită atenția ta.",
    titleModalHasErrors: "Informații incomplete sau incorecte",
    messageModalHasNoErrors:
      "Super! Formularul a fost trimis cu succes. Un membru al echipei noastre va lua legătura cu tine în curând.",
    titleModalHasNoErrors: "Formular trimis",
    fiscalInformation: "Informații Fiscale",
    companyRegistrationNumber: "Cod Unic de Înregistrare (CUI)",
    companyName: "Nume Companie",
    companyType: "Tip Companie",
    companyRegistrationCountry: "Țară Înregistrare Companie",
    euVatNumber: "Număr TVA UE",
    tradeRegistryNumber: "Număr Registrul Comerțului",
    invoiceSeries: "Serie facturare",
    invoiceNumber: "Numar facturare",
    europeanUniqueIdentifier: "Identificator European Unic",
    vatObligations: "Plătitor TVA",
    vatObligationsOpt1: "Da",
    vatObligationsOpt2: "Nu",
    companyLegalAddress: "Adresă Legală Companie",
    country: "Tară",
    city: "Oraș",
    county: "Județ",
    streetName: "Strada",
    number: "Număr",
    postalCode: "Cod Poștal",
    legalRepresentative: "Reprezentant Legal",
    firstName: "Prenume",
    lastName: "Nume",
    telephone: "Telefon",
    emailAddress: "Adresă de Email",
    bankDetails: "Detalii Bancare",
    iban: "IBAN",
    bankName: "Nume Bancă",
    swift: "Cod SWIFT",
    currency: "Monedă",
    documents: "Documente",
    uploadTheFollowingRequiredDocuments: "Documente pentru activarea contului",
    uploadTheFollowingOptionalDocuments:
      "Documente pentru înregistrarea biletelor la DITL (opțional)",
    optionalDocumentsDetails:
      "Acest serviciu este destinat organizatorilor de evenimente și este pus la dispoziție de către Nightz, gratuit. " +
      "Acest serviciu poate fi activat/dezactivat din formularul de publicare al evenimentului.",
    getTemplate: "Descarcă Template",
    name: "Nume",
    size: "Mărime",
    type: "Tip",
    modified: "Modificat",
    actions: "Acțiuni",
    finalStep: "Ultimul Pas",
    deleteAll: "Șterge Tot",
    uploadFiles: "Încarcă Fișiere",
    uploadFile: "Încarcă",
    back: "Înapoi",
    next: "Înainte",
    finalStep1: "Super!",
    finalStep2: "Ai completat formularul!",
    finalStep3:
      "Aruncă o ultimă privire pentru a te asigura că toate informațiile sunt corecte.",
    finalStep4:
      "Vom analiza cererea ta în curând și te vom notifica când contul tău va fi activat și vei putea publica evenimente.",
    sendApplication: "Trimite Cererea",
    okButton: "Ok",
    contactDetails: "Detalii de Contact",
    delete: "Șterge",
    view: "Vizualizează",
    saveDataAndSkipForNow: "Datele au fost salvate cu succes.",
    uploadedDocuments: "Documente încărcate",
    noDocumentsUploaded: "Nu ai încărcat niciun document",
    accordingToOriginalText:
      "Toate copiile documentelor trebuie semnate și marcate cu textul 'Conform cu originalul'. ",
  },
  eventTaxReports: {
    ticketRegistration: "Înregistrare Bilete",
    neccesaryDocuments: "Acte necesare depunerii",
    dowloadAll: "Descarcă tot",
    ditlRegistration: "Înregistrare DITL",
    taxReporting: "Raportare Taxe",
    attention: "Atenție!",
    attentionDescription:
      "Companiile care doresc ca raportarea sa fie efectuata de catre Nightz, trebuie sa incarce urmatoarele documente in sectiunea “Afacerea mea”:",
    availableBelow: "disponibil mai jos",
    autogenerated: "autogenerat",
    goToMyBusiness: "Mergi la Afacerea mea",
    justNGO: "(Doar ONG)",
    downloadTemplate: "Descarcă un document de tip template",
    viewDocument: "Vizualizează documentul",
  },

  eventStatistics: {
    dateSelectorLabelOpt1: "7 zile",
    dateSelectorLabelOpt2: "14 zile",
    dateSelectorLabelOpt3: "30 zile",
    dateSelectorLabelOpt4: "Custom",
    salesOverview: "Grafic Vânzări",
    revenueOverview: "Grafic Venituri",
    totalRevenue: "Venit Total",
    totalRevenueTickets: "Venit Total din Bilete",
    totalSoldTickets: "Total Bilete Vândute",
    revenueByTicketType: "Venituri pe Tip de Bilet",
    soldTicketsByTicketType: "Nr. de Bilete Vândute pe Tip de Bilet",
    totalActualSold: "Total",
  },

  invoicesTable: {
    create: "Crează",
    tableTitle: "Facturi",
    invoiceDetails: "Detalii factură",
    invoiceTotal: "Total",
    invoicePaid: "Plătit",
    invoiceUnpaid: "Neplătit",
    changeStatus: "Schimbă statusul",
    exportPdf: "Exportă pdf",
    exportXml: "Exportă xml",
    deleteInvoices: "Șterge facturi",
    documentType: "Tipul documentului",
    documentNumberInputPlaceholder: "Cautati dupa numarul documentului",
    companyNameInputPlaceholder: "Cautati dupa numele companiei",
    eventInputPlaceholder: "Cautati dupa numele evenimentului",
    amount: "Sumă",
    type: "Tip",
    created: "Creat",
    company: "Companie",
    status: "Status",
    noInvoices: "Nu există facturi, incercati filtrari",
    changeStatusTo: "Schimbă statusul în",
    statues: {
      invoiced: "FACTURAT",
      paid: "PLĂTIT",
    },
    invoiced: "Facturat",
    paid: "Plătit",
    name: "Nume",
    companyName: "Numele companiei",
    content: "Conținut",
    actions: "",
    documentName: "Denumirea",
    startDate: "Data de început",
    endDate: "Dată de sfârșit",
    formattedType: {
      INVOICE: "Factură",
      AUTO_INVOICE: "Autofactură",
      COMPENSATION_AGREEMENT: "Acord de compensare",
    },
    invoice: "Factură",
    autoInvoice: "Autofactură",
    compenAgreement: "Acord de compensare",

    INVOICE: "Factură",
    AUTO_INVOICE: "Autofactură",
    COMPENSATION_AGREEMENT: "Acord de compensare",
  },

  transactionsTable: {
    tableTitle: "Tranzacții",
    eventTransactions: "Tranzacțile evenimentului",
    export: "Exportă",
    createInvoice: "Creează Factură",
    searchIdAndSeries: "Caută după ID și Serie",
    searchNameMailPhone: "Caută după Nume, Email sau Telefon",
    orderId: "ID Comandă",
    clientOrderStatus: "Status Comandă Client",
    purchaseDate: "Data Achiziției",
    tickets: "Bilete",
    orderID: "Id comandă",
    purchaseDate: "Data achiziției",
    invoicingStatus: "Statusul facturii ",
    name: "Nume",
    phone: "Telefon",
    email: "Email",
    total: "Total",
    event: "Eveniment",
    company: "Companie",
    companyInvoiceStatus: "Status Factură Companie",
    orderStatus: "Status Comandă",
    genericError: "A apărut o eroare! Vă rugăm să reîncercați.",
    documentType: "Tipul documentului",
    startDate: "Dată de început",
    endDate: "Dată de sfârșit",
    status: "Status",
    export: "Exportă",
    statuses: {
      COMPLETED: "COMPLET",
      FAILED: "EȘUAT",
      PENDING: "îN AȘTEPTARE",
      REFUNDED: "RAMBURSAT",
    },
    invoice: "Facturează",
    message1: "Nu sunt tranzacții.",
    message2: "Odată ce o tranzacție este efectuată, aceasta va apărea aici.",
  },

  invoicesDetails: {
    titlePage: "Detalii Facturi",
    currentFilters: "Filtrele curente",
    eventId: "ID Eveniment",
    createdFrom: "Creat de la",
    createdTo: "Creat până la",
    companyId: "ID Companie",
    noDraftsData: "Nu există date pentru facturi în stadiul de draft",
  },

  ticketTable: {
    name: "Nume",
    serialNumber: "Număr Serie",
    initialTickets: "Bilete inițiale",
    initialVouchers: "Vouchere inițiale",
    remainingTickets: "Bilete disponibile",
    remainingVouchers: "Vouchere disponibile",
    displayedPrice: "Preț afișat (lei)",
    visibility: "Vizibilitate",
    genericError: "A apărut o eroare! Vă rugăm să reîncercați.",
    ticketTableNoDataTitle: "Nu există date disponibile pentru afișare",
    ticketTableNoDataDescription:
      "Odată ce un bilet este cumpărat, acesta va apărea aici",
    excelOptions: {
      ticketsOnly: "Doar Bilete",
      filteredTickets: "Bilete Filtrate",
    },
  },
  documents: {
    registrationCertificatefromNationalTradeRegistry:
      "Certificat unic de înregistrare / Certificat de înregistrare fiscală",
    contractNightzB2B: "Contract Nightz B2B",
    termsAndConditionsForPartners: "Termeni și condiții pentru parteneri",
    decontForm: "Formular decont",
    articleOfIncorporation: "Copie Act Constitutiv",
    ticketRegistrationForm: "Formular Înregistrare Bilete",
    mandateForNightz: "Împuternicire pentru Nightz",
    idCard: "Act de identitate",
    articleOfAssociation: "Statutul societății",
    unsoldTicketsForm: "Cerere Casare Bilete Nevandute",
    wasGenerated: "Documentul {{documentName}} a fost generat",
    wasNotGenerated: "Nu s-a putut genera documentul {{documentName}}",
  },
  deletedMessages: {
    deletedName: "Nume șters",
    deletedEmail: "Email șters",
    deletedPhone: "Telefon șters",
    deletedCompany: "Companie șters",
    deletedAccount: "Cont șters",
    deletedCui: "Cui șters",
    deletedStatus: "Status șters",
  },
  apiErrorCodes: {
    NIGHTZ_ERROR_SEATSIO_BOOKING_FAILED:
      "Locurile nu mai sunt disponibile. Vă rugăm să alegeți locuri diferite! Acest lucru se întâmplă dacă timpul de păstrare pentru locuri a expirat și altcineva le-a cumpărat. Daca locurile blocate nu au fost cumparate ele devin din nou libere in 15 min",
    NIGHTZ_ERROR_RESERVATION_FAILED:
      "Comanda nu este posibilă pentru că reservarea configurată nu mai este posibilă. Vă rugăm să ștergeți reservarea din coș și să încercați altă reservare",
  },

  resourceNotAvailable: {
    title: "Resursa nu este disponibilă",
    description:
      "Ne pare rău, nu am putut încărca resursa pe care o căutați. Poate că nu v-ați conectat cu utilizatorul corect",
  },
};

export default ro;
