import { gql } from "@apollo/client";

const SEARCH_LISTINGS = gql`
  query Event($term: String) {
    newSearch(term: $term) {
      event {
        name
        subCategory {
          name
        }
        images
        id
      }
    }
  }
`;

const GET_GEOREGIONS = gql`
  query GeoRegions {
    geoRegions {
      county
      id
    }
  }
`;

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
      subcategories {
        id
        name
      }
    }
  }
`;

const GET_EVENT_LIST = gql`
  query Events($geoRegionCounty: String, $categoryId: ID) {
    events(geoRegionCounty: $geoRegionCounty, categoryId: $categoryId) {
      general {
        id
        name
        images
        startDate
        endDate
        isPublished
        mainEventCountyVisibility
        openingHours
        subCategory {
          id
          name
        }
        category {
          id
          name
        }
        startPrice
      }
      address {
        venueName
        street
        city
        googleLocationLink
      }
      rating {
        bookLink
        priceRangeEnd
        priceRangeStart
        program
        rating
        reviewsCount
      }
    }
  }
`;

const GET_MAIN_EVENT_LIST = gql`
  query Trending($geoRegionCounty: String, $categoryId: String) {
    trending(geoRegionCounty: $geoRegionCounty, categoryId: $categoryId) {
      general {
        id
        images
        name
        startDate
        endDate
        isPublished
        mainEventCountyVisibility
        openingHours
        subCategory {
          id
          name
        }
        category {
          id
          name
        }
        fields
        startPrice
      }
      address {
        venueName
        street
        city
        googleLocationLink
      }
      rating {
        bookLink
        priceRangeEnd
        priceRangeStart
        program
        rating
        reviewsCount
      }
    }
  }
`;

const GET_EVENT = gql`
  query Event($eventId: ID!) {
    event(id: $eventId) {
      general {
        id
        images
        name
        description
        organizerEmail
        organizerPhone
        organizerWebsite
        isPublished
        status
        fields
        organizerDisplayName
        company {
          avatar
        }
        startDate
        endDate
        subCategory {
          name
          id
        }
        category {
          id
          name
        }
        tags {
          id
          name
        }
        visibility
        isPermanent
        openingHours
        seatsIoEventKey
      }
      address {
        venueName
        street
        city
        county
        googleLocationLink
      }
      tickets {
        ticketTypes {
          price
          id
          name
          available
          ticketOrder
          total
          isPublished
          series
          sellEndDate
          sellStartDate
          order
          description
          category {
            name
          }
          promotionType
          promotionMetadata
        }
        borderColor
        comission
        entryType
      }
      rating {
        bookLink
        priceRangeEnd
        priceRangeStart
        program
        rating
        reviewsCount
      }
      booking {
        areas {
          id
          name
          price
          description
        }
        id
        bookingInterval
        bookingDuration
        automaticConfirmation
        holdingFee
        moduleStatus
        moduleStatusBookingMenu
        menus {
            id
            name
            description
            category
            price
            quantity
            quantityMetric
        }
      }
    }
  }
`;

export {
  GET_EVENT_LIST,
  GET_EVENT,
  SEARCH_LISTINGS,
  GET_CATEGORIES,
  GET_GEOREGIONS,
  GET_MAIN_EVENT_LIST,
};
