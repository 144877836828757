import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Link } from "@mui/material";
import SvgColor from "../svg-color";
import palette from "src/theme/palette";
import { useSettingsContext } from "../settings";

// ----------------------------------------------------------------------

const LogoSmall = () => {
  const { themeMode } = useSettingsContext();

  const logo = (
    <SvgColor
      src="/logo/white_nightz_icon.svg"
      sx={{
        width: 194,
        height: 36,
        cursor: "pointer",
        bgcolor:
          themeMode === "dark" ? "white" : palette("dark").background.default,
      }}
    />
  );

  return (
    <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
};

export default LogoSmall;
