// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS = "";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATHS = {
  root: ROOTS,
  maintenance: {
    root: path(ROOTS, "/maintenance"),
  },
  user: {
    root: path(ROOTS, "/user"),
    new: path(ROOTS, "/user/new"),
    list: path(ROOTS, "/user/list"),
    cards: path(ROOTS, "/user/cards"),
    profile: path(ROOTS, "/user/profile"),
    tickets: path(ROOTS, "/user/tickets"),
    ticket: (eventId, eventName) =>
      path(
        ROOTS,
        `/user/tickets/${encodeURIComponent(eventId)}/${encodeURIComponent(
          eventName
        )}`
      ),
    account: path(ROOTS, "/user/account"),
    edit: (name) => path(ROOTS, `/user/${name}/edit`),
    demoEdit: path(ROOTS, `/user/reece-chung/edit`),
  },
  business: {
    root: path(ROOTS, "/business"),
  },
  company: {
    root: path(ROOTS, "/company/register"),
    register: path(ROOTS, "/company/register"),
  },
  booking: {
    root: path(ROOTS, "/booking"),
    new: (eventId) => path(ROOTS, `/booking/new/${eventId}`),
    newWizard: (eventId) => path(ROOTS, `/booking/newWizard/${eventId}`),
    details: (bookingId) => path(ROOTS, `/booking/details/${bookingId}`),
  },
  listings: {
    root: path(ROOTS, "/listings"),
    list: path(ROOTS, "/listings/list"),
    success: path(ROOTS, "/listings/success"),
    checkout: path(ROOTS, "/listings/checkout"),
    fail: path(ROOTS, "/listings/fail"),
    new: path(ROOTS, "/listings/details/new"),
    view: (subcategory, name, id) =>
      path(ROOTS, `/listings/details/${subcategory}/${name}/${id}`),
    edit: (id) => path(ROOTS, `/listings/details/${id}/edit`),
  },

  iframe: {
    listingsBookingsView: (subcategory, name, id) =>
      path(ROOTS, `/listings/iframe/details/${subcategory}/${name}/${id}`),
  },

  dashboard: {
    root: path(ROOTS, "/dashboard"),
    eventEditor: (id) =>
      path(ROOTS, `/dashboard/my-listings/event-editor/${id}`),
    addEventWizard: path(ROOTS, "/dashboard/wizard-event"),
    myListings: path(ROOTS, "/dashboard/my-listings"),
  },

  myBusiness: {
    root: path(ROOTS, "/my-business/business-details"),
    businessDetails: path(ROOTS, "/my-business/business-details"),
  },

  adminInterface: {
    root: path(ROOTS, "/admin-interface"),
    companiesList: path(ROOTS, "/admin-interface/companies-list"),
    platformSettings: path(ROOTS, "/admin-interface/platform-settings"),
    view: (id) =>
      path(ROOTS, `/admin-interface/companies-list/company/details/${id}`),
  },

  invoices: {
    root: path(ROOTS, "/invoices"),
    create: path(ROOTS, "/invoices/invoice/create"),
    preview: path(ROOTS, "/invoices/preview"),
  },

  transactions: {
    root: path(ROOTS, "/transactions"),
  },

  auxiliary: {
    contact: path(ROOTS, "/contact-us"),
    aboutus: path(ROOTS, "/about-us"),
    termsAndConditions: path(ROOTS, "/terms-and-conditions"),
    privacyPolicy: path(ROOTS, "/privacy-policy"),
    cookiesPolicy: path(ROOTS, "/cookies-policy"),
    csaePolicy: path(ROOTS, "/csae-policy"),
  },
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  about: "/about-us",
  contact: "/contact-us",
  page404: "/404",
  pageResourceNotAvailable: "/resourceNotAvailable",
  page500: "/500",
  components: "/components",
};
