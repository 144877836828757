import { Box, Link, Button, Divider, Typography, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// routes
import { PATHS } from "src/routes/paths";
// components
import Iconify from "src/components/iconify/Iconify";
import { OrderCompleteIllustration } from "src/assets/illustrations";
import { useLocales } from "src/locales";
import { PrimaryButton, SecondaryButton } from "../events/listing-details/listingButtons";
import { useAuthContext } from "src/hooks/useAuthContext";
import getRoles from "src/utils/getRoles";

const ticketsImg = require("../../../assets/checkout-success-images/glass-tickets.png")
const reservationImg = require("../../../assets/checkout-success-images/glass-reservation.png")
const cartImg = require("../../../assets/checkout-success-images/glass-cart.png")

const CheckoutSuccess = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const shadowAccountFlag = getRoles().ShadowOnly.includes(user.role);

  const { orderType, eventId, eventName, bookedSessionId } = location.state;

  const handleTicketsButton = () => {
    navigate(PATHS.user.ticket(eventId, eventName));
  };

  const handleReservationButton = () => {
    bookedSessionId ? navigate(PATHS.booking.details(bookedSessionId)) : navigate(PATHS.user.tickets);
  };

  const handleReturnButton = () => {
    navigate(PATHS.listings.list);
  };

  const mappings = {
    tickets: { 
      message: translate('checkout.congrats.ticket'), 
      actionName: translate('checkout.congrats.ticketAction'), 
      action: handleTicketsButton,
      imgSrc: ticketsImg, 
      },
    reservation: { 
      message: translate('checkout.congrats.table'), 
      actionName: translate('checkout.congrats.tableAction'), 
      action: handleReservationButton,
      imgSrc: reservationImg, 
    },
    order: { 
      message: translate('checkout.congrats.order'), 
      actionName: translate('checkout.congrats.ticketAction'), 
      secondActionName: translate('checkout.congrats.tableAction'),
      action: handleTicketsButton,
      secondAction: handleReservationButton, 
      imgSrc: cartImg
    }
  };
  
  const { message, actionName, secondActionName, action, secondAction, imgSrc } = mappings[orderType];

  return (
    <Box sx={{ p: 4, maxWidth: 480, margin: "auto" }}>
      <Box sx={{ display: 'flex', flex: 1, flexDirection:'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <img
          className="checkout-success-image"
          src={imgSrc}
          alt="success"
          width={200}
        />

        <Typography variant="h4" sx={{mt:3}} paragraph>
          {message}
        </Typography>

        <Typography align="center" paragraph>
          {translate("checkout.success.mail2")} {translate("checkout.success.problems")}
        </Typography>

        {orderType === "order" ? (
          <Box sx={{width: '100%', my: 3}}>
            <PrimaryButton
              fullWidth
              size="large"
              variant="contained"
              onClick={handleReturnButton}
              sx={{ whiteSpace: "nowrap", mb: 2 }}
            >
              {translate("checkout.congrats.callToAction")}
            </PrimaryButton>
            {!shadowAccountFlag && 
              <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                <SecondaryButton
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={action}
                >
                  {actionName}
                </SecondaryButton>
                <SecondaryButton
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={secondAction}
                >
                  {secondActionName}
                </SecondaryButton>
              </Box>
            }
          </Box>
        ) : (
          <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between', gap: 2, my: 3}}>
              <PrimaryButton
                fullWidth
                size="large"
                variant="contained"
                onClick={handleReturnButton}
              >
                {translate("checkout.congrats.callToAction")}
              </PrimaryButton>
              {!shadowAccountFlag && 
                <SecondaryButton
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={action}
                >
                  {actionName}
                </SecondaryButton>}
            </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckoutSuccess;
