// @mui
import { styled, alpha } from "@mui/material/styles";
// auth
import { useAuthContext } from "../../../hooks/useAuthContext";
// components
import { CustomAvatar } from "../../../components/custom-avatar";

// ----------------------------------------------------------------------


const StyledRoot = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 1),
  width: "fit-content",
  alignSelf: "center",
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccountMini() {
  const { user } = useAuthContext();

  return (
    <StyledRoot>
        <CustomAvatar src={user?.avatar} alt={user?.email} name={user?.email} />
    </StyledRoot>
)}
