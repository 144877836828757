import Page from "../Page";
import { styled } from "@mui/material/styles";
import { Grid, Container } from "@mui/material";
import { useLocales } from "../../locales";

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(11),
  },
}));

const CsaePolicy = () => {
  const { translate } = useLocales();
  return (
    <Page title={"CSAE"}>
      <RootStyle>
        <Container sx={{ my: 10, "& ul": { paddingLeft: 2 } }}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className="no_copy">
                <h1>Prevention of Child Sexual Abuse and Exploitation (CSAE)</h1>
                <br></br>
                <p>Last update: 1st February 2025 </p>
                <br></br>
                <br></br>
                <h2>1. Published Standards Against Child Sexual Abuse and Exploitation (CSAE) </h2>
                <br></br>
                <p>
                Nightz maintains a zero-tolerance policy toward Child Sexual Abuse and Exploitation (CSAE) and enforces strict measures to prevent, detect, and eliminate such content or behavior. 
                  <br></br>
                  <br></br>
                  We prohibit the following activities on our platform: <br></br>
                  <br></br>
                  <ul>
                    <li>Creation, sharing, or possession of Child Sexual Abuse Material (CSAM), including AI-generated or deepfake content. </li>
                    <li>Grooming, which includes engaging with a minor in a way intended to manipulate, exploit, or prepare them for abuse. </li>
                    <li>Solicitation or coercion of minors into sending explicit content or engaging in illegal activities. </li>
                    <li>Advertising, requesting, or promoting any CSAE-related services or content. </li>
                  </ul>
                  <br></br>
                  Any violation will result in immediate account suspension, permanent bans, and reporting to law enforcement.  <br></br>
                  <br></br>
                </p>
                <h2>2. In-App Mechanism for User Feedback and Reporting </h2>
                <br></br>
                <p>
                Nightz provides multiple reporting tools to ensure users can easily report any CSAE-related concerns. <br></br>
                  <br></br>
                  <h3>2.1. In-App Reporting Tools </h3>
                  <br></br>
                  <ul>
                    <li>Users can report suspicious content or behavior directly within the app using the report options in individual chats and group chats. </li>
                    <li>Reports can be made anonymously to encourage safe reporting. </li>
                  </ul>
                  <br></br>
                  <h3>2.2. Response to Reports </h3>
                  <br></br>
                  <ul>
                    <li>All CSAE-related reports are prioritized and reviewed by our safety team within 24 hours.  </li>
                    <li>If CSAE content is identified, it will be immediately removed, and the responsible user will be banned.  </li>
                    <li>Confirmed violations will be reported to the National Center for Missing & Exploited Children (NCMEC) and other legal authorities. </li>
                  </ul>
                  <br></br>
                </p>
                <h2>3. Addressing Child Sexual Abuse Material (CSAM) </h2>
                <br></br>
                <p>
                    To prevent and detect CSAM, Nightz implements industry-standard detection and enforcement mechanisms, including:<br></br>
                  <br></br>
                  <ul>
                    <li>Proactive moderation of content flagged as explicit or inappropriate. </li>
                    <li>Strict enforcement actions, including permanent bans and law enforcement referrals for any CSAM-related offenses. </li>
                  </ul>
                  <br></br>
                </p>
                <h2>4. Compliance with Child Safety Laws </h2>
                <br></br>
                <p>
                    Nightz adheres to all applicable child protection laws and regulations, including: <br></br>
                  <br></br>
                  <ul>
                    <li>U.S.: Children’s Online Privacy Protection Act (COPPA), PROTECT Our Children Act (2008), and EARN IT Act. </li>
                    <li>EU: General Data Protection Regulation (GDPR-K, Article 8) and EU Digital Services Act (DSA). </li>
                    <li>UK: Online Safety Act (2023) and Age-Appropriate Design Code. </li>
                    <li>International: UN Convention on the Rights of the Child (UNCRC) and WePROTECT Global Alliance standards. </li>
                  </ul>
                  <br></br>
                  We ensure compliance by:<br></br>
                  <br></br>
                  <ul>
                    <li>Strictly verifying the age of users to prevent underage access.  </li>
                    <li>Limiting data collection from minors and requiring parental consent where applicable.  </li>
                    <li>Ensuring transparency in safety measures through regular compliance audits.  </li>
                  </ul>
                  <br></br>
                </p>
                <h2>5. Child Safety Point of Contact </h2>
                <br></br>
                <p>
                 For child safety concerns, users, parents, or authorities can contact our Child Safety Officer at: <br></br>
                  <br></br>
                  Email: contact@nightz.app <br></br>
                  Phone: +40 728 325 811 <br></br>
                  <br></br>
                  This contact is monitored 24/7 for urgent child safety matters. <br></br>
                  <br></br>
                  Additionally, users can report immediate threats to: <br></br>
                  <br></br>
                  <ul>
                    <li>National Center for Missing & Exploited Children (NCMEC) [<a href="www.missingkids.org">www.missingkids.org</a>] </li>
                    <li>INHOPE global reporting network [<a href="www.inhope.org">www.inhope.org</a>] </li>
                  </ul>
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default CsaePolicy;
