import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Typography, Stack } from "@mui/material";
// routes
import useResponsive from "../../../../hooks/useResponsive";
import GenericShowcaseSummaryDetails from "./GenericShowcaseSummaryDetails";
import RatingShowcaseSummaryDetails from "./RatingShowcaseSummaryDetails";
import { EVENT_SUBCATEGORIES_CONTSTANTS } from "../../../../config/constants/event-subcategories-constants";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
}));

// ----------------------------------------------------------------------

EventShowcaseSummary.propTypes = {
  product: PropTypes.object,
  address: PropTypes.object,
  rating: PropTypes.object,
};

export default function EventShowcaseSummary({
  general,
  address,
  rating,
  onAddCart,
  ...other
}) {
  const isMobile = useResponsive("down", "md");

  return (
    <RootStyle {...other}>
      <Typography
        color="white"
        variant="h2"
        marginBottom={3}
        lineHeight={1}
        sx={{ maxWidth: isMobile ? "100%" : "40%" }}
      >
        {general.name}
      </Typography>

      <Stack
        sx={{ color: "white" }}
        direction="row"
        spacing={1}
        alignItems={"center"}
      >
        {[
          EVENT_SUBCATEGORIES_CONTSTANTS.TYPE.RESTAURANT,
          EVENT_SUBCATEGORIES_CONTSTANTS.TYPE.LANDMARK,
          EVENT_SUBCATEGORIES_CONTSTANTS.TYPE.EXPERIENCE,
        ].includes(general.subCategory.name) ? (
          <RatingShowcaseSummaryDetails general={general} rating={rating} address={address} />
        ) : (
          <GenericShowcaseSummaryDetails general={general} address={address} />
        )}
      </Stack>
    </RootStyle>
  );
}
