import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#E0E0E0",
  300: "#C1C1C1",
  400: "#A2A2A2",
  500: "#838383",
  600: "#646464",
  700: "#454545",
  800: "#1C1C1D",
  900: "#070707",
};

const PRIMARY = {
  lighter: "#BFE0FF",
  light: "#86C4FF",
  main: "#007AFF",
  dark: "#1C6FBD",
  darker: "#084278",
  contrastText: "#FFFFFF",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#006C9C",
  darker: "#003768",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#D8FBDE",
  light: "#86E8AB",
  main: "#34C759",
  dark: "#1B806A",
  darker: "#0A5554",
  contrastText: "#fff",
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FF9500",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: "#fff",
};

const ERROR = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF3B30",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#fff",
};

const LIGHT = {
  lighter: "#FFFFFF",
  light: "#FFF",
  main: "#FFF",
  dark: GREY[200],
  darker: GREY[300],
  contrastText: GREY[900],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  light: LIGHT,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode) {
  const light = {
    ...COMMON,
    mode: "light",
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
      superDisabled: GREY[300],
    },
    background: {
      paper: "#fff",
      default: "#fff",
      neutral: GREY[200],
      card: "#fff",
      secondary: GREY[100],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: "dark",
    text: {
      primary: "#fff",
      secondary: GREY[500],
      disabled: GREY[600],
      superDisabled: GREY[700],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
      card: "#131313",
      secondary: "#111111",
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return themeMode === "light" ? light : dark;
}
