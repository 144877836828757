import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Rating,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// components
import Iconify from "../../../../components/iconify";
import { useLocales } from "../../../../locales";
import { PrimaryButton, SecondaryButton } from "./listingButtons";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import useResponsive from "../../../../hooks/useResponsive";
import { LocationIcon } from "src/assets/icons/LocationIcon";
import { ClockIcon } from "src/assets/icons/ClockIcon";
import { checkOpeningHours } from "src/utils/dateUtils";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  position: "relative",
}));

// ----------------------------------------------------------------------

VenueDetailsSummary.propTypes = {
  onGotoStep: PropTypes.func,
  product: PropTypes.object,
  isShortVersion: PropTypes.bool,
};

export default function VenueDetailsSummary({
  product,
  links,
  isShortVersion,
  ...other
}) {
  const {
    bookLink,
    rating,
    reviewsCount,
    priceRangeStart,
    priceRangeEnd,
    program,
  } = product.rating;
  const { organizerPhone: phone } = product.general;
  const isMobile = useResponsive("down", "sm");

  const { name } = product.general;

  const address = product.address;
  const theme = useTheme();
  //uses the correct dictionary depending on the selected language
  const { translate } = useLocales();
  const { pathname } = useLocation();

  const getPriceRange = () => {
    const averagePrice = Math.round(
      parseFloat((priceRangeStart + priceRangeEnd) / 2)
    );
    return [...Array(averagePrice || 1)].map((_) => "$").join("");
  };

  const handleCallButtonClick = () => {
    window.location.href = `tel:${phone}`;
  };

  const shouldDisplayPriceRange = () => {
    if (priceRangeStart > 0 && priceRangeEnd > 0) {
      return true;
    }
    return false;
  };
  const shouldDisplayAddress = () => {
    if (
      address.city.length > 0 &&
      address.street.length > 0 &&
      address.venueName.length > 0
    ) {
      return true;
    }
    return false;
  };

  const isOpen = checkOpeningHours(product.general.openingHours);

  return (
    <RootStyle {...other}>
      <Typography color="white" variant="h3" paragraph>
        {name}
      </Typography>

      {shouldDisplayAddress() && (
        <ListItem
          sx={{
            pl: 0,
            color: "white",
            ...(pathname.includes("/listings/") && {
              ":hover": {
                cursor: "pointer",
              },
            }),
          }}
          onClick={() => {
            if (pathname.includes("/listings/")) {
              window.open(address.googleLocationLink);
            }
          }}
        >
          <ListItemIcon>
            <LocationIcon/>
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "subtitle1" }}
            primary={`${address.venueName}, ${address.street}, ${address.city}`}
          />
        </ListItem>
      )}

      {product.general.openingHours.length ? (
        <ListItem sx={{ pl: 0, color: isOpen ? theme.palette.success.main : theme.palette.error.main }}>
          <ListItemIcon>
            <ClockIcon/>
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "subtitle1" }}
            primary={isOpen ? translate("productPage.openNow") : translate("productPage.closedNow")}
          />
        </ListItem>
      ) : null}

      {program && !product.general.openingHours.length ? (
        <ListItem sx={{ pl: 0, color: "white" }}>
          <ListItemIcon>
            <ClockIcon/>
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "subtitle1" }}
            primary={program}
          />
        </ListItem>
      ) : null}

      {reviewsCount !== 0 && reviewsCount && (
        <Stack
          sx={{ mt: 2, mb: isShortVersion ? 0 : 2, color: "white" }}
          direction="row"
          spacing={1}
          alignItems={"center"}
        >
          <Typography variant="subtitle1">{rating}</Typography>
          <Rating precision={0.5} size="small" value={rating} readOnly />
          <Typography variant="subtitle1">{`(${
            reviewsCount || 0
          })`}</Typography>
        </Stack>
      )}

      {!isShortVersion && shouldDisplayPriceRange() && (
        <Typography variant="subtitle1" sx={{ mb: 2, color: "white" }}>
          {`${translate("productPage.priceRange")}: ${getPriceRange()}`}
        </Typography>
      )}

      {!isShortVersion && (
        <Stack
          spacing={2}
          direction={isMobile ? "column" : "row"}
          sx={{
            mt: 5,
            mb: 4,
          }}
        >
          {bookLink && (
            <SecondaryButton
              fullWidth
              size="large"
              variant="contained"
              disabled={!bookLink}
              startIcon={<Iconify icon={"mdi:open-in-new"} />}
              onClick={() => {
                if (bookLink) {
                  window.open(bookLink);
                }
              }}
              sx={{ whiteSpace: "nowrap", textWrap: isMobile ? "wrap" : null }}
            >
              {translate("productPage.viewMenu")}
            </SecondaryButton>
          )}
          {phone && (
            <PrimaryButton
              fullWidth={Boolean(bookLink)}
              size="large"
              variant="contained"
              startIcon={<Iconify icon={"mdi:phone"} />}
              onClick={handleCallButtonClick}
            >
              {phone}
            </PrimaryButton>
          )}
          {product.general.id === 'cm65af9ja0000l408r8o53rys' && <elevenlabs-convai agent-id="LpOqJ4syDF6nKVOBsD8h"></elevenlabs-convai>}
        </Stack>
      )}
    </RootStyle>
  );
}
