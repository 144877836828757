// @mui
import { Box, Stack } from "@mui/material";
// config
import { NAV } from "../../../config-global";
// utils
import { hideScrollbarX } from "../../../utils/cssStyles";
// components
import { NavSectionMini } from "../../../components/nav-section";
//
import NavToggleButton from "./NavToggleButton";

import getSidebarConfig from "./config-navigation";
import { useMemo } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useLocales } from "../../../locales";
import Logo from "src/components/logo";
import NavAccount from "./NavAccount";
import LogoSmall from "src/components/logo/LogoSmall";
import NavAccountMini from "./NavAccountMini";

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useAuthContext();
  const { currentLang } = useLocales();

  const navConfig = useMemo(
    () => getSidebarConfig(user.role, currentLang.value),
    [user, currentLang]
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: "25vh",
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        spacing={4}
        sx={{
          pb: 2,
          pt: 4,
          height: 1,
          position: "fixed",
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LogoSmall />
        </Box>

        {user.role !== "shadow" && <NavAccountMini />}

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
