import { Box } from "@mui/material";

export default function StickyFooter({ children }) {
  return (
    <Box sx={{
      position: "fixed",        /* Fixes the footer relative to the viewport */
      bottom: 0,              /* Positions it at the bottom */
      left: 0,                /* Aligns it to the left */
      width: "100vw",           /* Sets the width to 100% of the viewport width */
      height: "80px",           /* Sets a fixed height (adjust as needed) */
      backdropFilter: "blur(10px)", /* Applies a blur effect to the background */
      color: "#fff",            /* Text color */
      textAlign: "center",     /* Centers the text horizontally */
      lineHeight: "80px",      /* Centers the text vertically (matches height) */
      zIndex: 100,          /* Ensures it overlays other elements */
    }}>
      {children}
    </Box>
  );
}
