import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
// routes
import { PATHS } from "../../../../routes/paths";
import Iconify from "../../../../components/iconify";
import { useState } from "react";
import { useLocales } from "../../../../locales";
import {
  fDate,
  fTime,
  getDateWithoutTimezoneChange,
} from "../../../../utils/formatTime";
import { PrimaryButton, SecondaryButton } from "./listingButtons";
import Chip from "../../../../components/Chip";
import {
  mapReservationStatusToColor,
  mapReservationStatusToTranslation,
} from "../../../../utils/reservationFunctions";
import { format } from "date-fns";
import dayjs from "dayjs";
import { ClockIcon } from "src/assets/icons/ClockIcon";
import { LocationIcon } from "src/assets/icons/LocationIcon";
import DateIcon from "src/assets/icons/DateIcon";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  position: "relative",
}));

// ----------------------------------------------------------------------

EventDetailsSummary.propTypes = {
  product: PropTypes.object,
  onClickMyActivitiesPageFunctions: PropTypes.object,
  bookedSession: PropTypes.object,
};

export default function EventDetailsSummary({
  product,
  onClickMyActivitiesPageFunctions = {},
  bookedSession = null,
  ...other
}) {
  const navigate = useNavigate();
  const { name, startDate, endDate } = product.general;
  const { address } = product;
  const { pathname } = useLocation();
  const isMyActivitiesPage =
    Object.entries(onClickMyActivitiesPageFunctions).length > 0;
  const hasBookedSession = bookedSession !== null;
  const [showEventDialog, setShowEventDialog] = useState(false);

  //uses the correct dictionary depending on the selected language
  const { translate } = useLocales();

  const formatEventDate = (start, end) => {
    const startDateString = start?.split("T")[0];
    const endDateString = end?.split("T")[0];

    const startDate = new Date(start);
    const endDate = new Date(end);

    if (startDateString === endDateString) {
      return startDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    } else {
      const startFormatted = startDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const endFormatted = endDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return `${startFormatted} - ${endFormatted}`;
    }
  };

  return (
    <RootStyle {...other}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography color="white" variant="h3" paragraph>
          {name}
        </Typography>
        {hasBookedSession && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
            <Chip
              label={translate(
                mapReservationStatusToTranslation(bookedSession.status)
              )}
              color={mapReservationStatusToColor(bookedSession.status)}
            />
          </Box>
        )}
      </Stack>

      <ListItem sx={{ pl: 0, color: "white" }}>
        <ListItemIcon>
          <DateIcon/>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: "subtitle1" }}
          primary={
            hasBookedSession
              ? fDate(bookedSession.bookedDate)
              : formatEventDate(startDate, endDate)
          }
        />
      </ListItem>

      <ListItem sx={{ pl: 0, color: "white" }}>
        <ListItemIcon>
          <ClockIcon/>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: "subtitle1" }}
          primary={
            hasBookedSession
              ? fTime(getDateWithoutTimezoneChange(bookedSession.bookedHour))
              : `${fTime(startDate)} - ${fTime(endDate)}`
          }
        />
      </ListItem>

      <ListItem
        sx={{
          pl: 0,
          color: "white",
          ...(pathname.includes("/listings/") && {
            ":hover": {
              cursor: "pointer",
            },
          }),
        }}
        onClick={() => {
          if (pathname.includes("/listings/")) {
            window.open(address.googleLocationLink);
          }
        }}
      >
        <ListItemIcon>
          <LocationIcon/>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: "subtitle1" }}
          primary={`${address.venueName}, ${address.street}, ${address.city}`}
        />
      </ListItem>
      {isMyActivitiesPage === true && (
        <Stack direction="row" spacing={2} sx={{ mt: 5, mb: 4 }}>
          {onClickMyActivitiesPageFunctions?.hasOwnProperty(
            "navigateToTicketsPage"
          ) && (
            <PrimaryButton
              fullWidth
              size="large"
              variant="contained"
              startIcon={<Iconify icon={"mdi:open-in-new"} />}
              onClick={
                onClickMyActivitiesPageFunctions?.navigateToTicketsPage ??
                undefined
              }
              sx={{ whiteSpace: "nowrap" }}
            >
              {translate("productPage.viewTickets")}
            </PrimaryButton>
          )}
          {onClickMyActivitiesPageFunctions?.hasOwnProperty(
            "navigateToBookingPage"
          ) && (
            <SecondaryButton
              fullWidth
              size="large"
              variant="contained"
              onClick={
                onClickMyActivitiesPageFunctions?.navigateToBookingPage ??
                undefined
              }
            >
              {translate("productPage.viewBooking")}
            </SecondaryButton>
          )}
        </Stack>
      )}
      <Dialog
        maxWidth={"sm"}
        open={showEventDialog}
        onClose={() => {
          setShowEventDialog(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {translate("productPage.dialogTitle")}
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              {translate("productPage.dialogContent")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setShowEventDialog(false);
            }}
          >
            {translate("confirmation.close")}
          </Button>
          <Button
            onClick={() => {
              setShowEventDialog(false);
              navigate(PATHS.listings.checkout);
            }}
            autoFocus
          >
            {translate("productPage.dialogAction")}
          </Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}
