import PropTypes from "prop-types";
import { Card, CardContent, Typography } from "@mui/material";
import { fCurrency } from "../../../../utils/formatNumber";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useLocales } from "../../../../locales";
import Markdown from "../../../../components/Markdown";

ProductCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  quantityString: PropTypes.string,
  price: PropTypes.number,
};

export default function ProductCard({ name, description, quantityString, price, children }) {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Card
      raised
      sx={{
        p: 2,
        mt: 2,
        backgroundColor: theme.palette.grey[800],
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        minHeight: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">{name}</Typography>
        {description && <Typography mt={1}>{description.replace(/<\/?(p|h2|h6|div|br\s*\/?)>/gi, '')}</Typography>}
        {quantityString && <Typography mt={1} variant="caption" color={"#666666"}>{quantityString}</Typography>}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="subtitle1"
          align="right"
          component={"div"}
          sx={{pr: 0.5}}
        >
          {`${fCurrency(price)} lei`}
        </Typography>
        <div style={{flexGrow: 1}}></div>
        {children}
        <div style={{flexGrow: 1}}></div>
      </div>
    </Card>
  );
}
