import { Link as RouterLink, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
} from "@mui/material";
// routes
// components
import { PATHS } from "../../routes/paths";
import SocialsButton from "../../components/SocialsButton";
import Logo from "../../components/logo";
import { useSettingsContext } from "../../components/settings";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: "Nightz",
    children: [
      //{ name: 'About us', href: PATH_PAGE.about },
      {
        name: {
          en: "About us",
          ro: "Despre noi",
        },
        href: PATHS.auxiliary.contact,
      },
      //{ name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: "Legal",
    children: [
      {
        name: {
          en: "Terms and Conditions",
          ro: "Termeni si Conditii",
        },
        href: PATHS.auxiliary.termsAndConditions,
      },
      {
        name: {
          en: "Cookies Policy",
          ro: "Politica de Cookies",
        },
        href: PATHS.auxiliary.cookiesPolicy,
      },
      {
        name: {
          en: "Privacy Policy",
          ro: "Politica de Confidentialitate",
        },
        href: PATHS.auxiliary.privacyPolicy,
      },
      {
        name: {
          en: "CSAE Policy",
          ro: "Politica de CSAE",
        },
        href: PATHS.auxiliary.csaePolicy,
      },
    ],
  },
  {
    headline: "Contact",
    children: [
      {
        name: {
          en: "contact@nightz.app",
          ro: "contact@nightz.app",
        },
        href: "#",
      },
      {
        name: {
          en: "Bucharest, Romania",
          ro: "Bucuresti, Romania",
        },
        href: "#",
      },
    ],
  },
];

const message = {
  ro: "Platforma care ii aduce impreuna pe organizatori si pe petrecareti.",
  en: "Platform that brings together organizers and partygoers.",
};

const rights = {
  ro: "Toate drepturile rezervate",
  en: "All rights reserved",
};

const RootStyle = styled("div")(({ theme }) => ({
  position: "static",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter({ isCustomDesign, isLightTheme }) {
  //todo hardcode
  const currentLanguage = "en";
  const { themeLayout } = useSettingsContext();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const hasMargin =
    themeLayout === "vertical" &&
    isDesktop === true &&
    ![
      "contact-us",
      "terms-and-conditions",
      "cookies-policy",
      "privacy-policy",
      "csae-policy"
    ].includes(pathname.split("/")[1]);

  return (
    <RootStyle
      style={{
        ...(isCustomDesign && {
          backgroundColor: isLightTheme ? "#fff" : "#000",
        }),
      }}
    >
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {message[currentLanguage]}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              {LINKS.map((list, index) => (
                <Stack key={index} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link, index) => (
                    <Link
                      to={link.href}
                      key={index}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: "block" }}
                    >
                      {link.name[currentLanguage]}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          © 2024. {rights[currentLanguage]}
        </Typography>
      </Container>
    </RootStyle>
  );
}
