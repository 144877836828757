import { areISOStringsEqual, isAfterTomorrow, isSameDay, isToday } from "../../../../utils/dateUtils";
import { isTomorrow } from "date-fns";

export function getBookingDays(eventGeneralData, selectedDate, datesParam, language) {
  selectedDate = selectedDate  || getDateValue(new Date().toISOString())
  
  const firstOpenDay = datesParam?.[0];
  if (!firstOpenDay) return [];
  const isFirstOpenDayToday = isToday(new Date(firstOpenDay?.name));

  if (isSameDay(eventGeneralData?.startDate, eventGeneralData?.endDate)) {
    return [
      {
        value: getDateValue(eventGeneralData?.startDate),
        label: getDate(eventGeneralData?.startDate, true, language),
      },
    ];
  }

  const isSelectedDateFirstOpenDay = areISOStringsEqual(firstOpenDay.name, selectedDate);
  let showTomorrow;
  if (isFirstOpenDayToday) {
    showTomorrow = datesParam?.[1] ? isTomorrow(new Date(datesParam[1].name)) : false;
  } else {
    showTomorrow = isTomorrow(new Date(firstOpenDay.name));
  }

  const dates = [
    ...(isFirstOpenDayToday
      ? [
        {
          value: getDateValue(todayWTmz().toISOString()),
          label: language === 'en' ? 'Today' : 'Azi',
        },
      ]
      : []),
    ...(showTomorrow
      ? [
        {
          value: getDateValue(todayWTmz().toISOString(), 1),
          label: language === 'en' ? 'Tomorrow' : 'Mâine',
        },
      ]
      : []),
    ...(isAfterTomorrow(new Date(firstOpenDay.name))
      ? [
        {
          value: getDateValue(firstOpenDay?.name),
          label: getDate(firstOpenDay?.name, true,language),
        },
      ]
      : []),
    ...(!isToday(new Date(selectedDate)) &&
    !isTomorrow(new Date(selectedDate)) &&
    !isSelectedDateFirstOpenDay
      ? [
        {
          value: selectedDate,
          label: getDate(selectedDate, true,language),
        },
      ]
      : []),
    {
      value: 'OTHER',
      label: language === 'en' ? 'Other' : 'Altă zi',
    },
  ];

  return dates;
}

const getDate = (inputDate, shortNames = false, language = 'en') => {
  const longWeekdays = language === 'en' ? [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ] : [
    'Duminică',
    'Luni',
    'Marți',
    'Miercuri',
    'Joi',
    'Vineri',
    'Sâmbătă',
  ];

  const shortWeekdays = language === 'en' ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm'];

  const weekdays = shortNames ? shortWeekdays : longWeekdays;

  const date = new Date(inputDate);
  const dayOfWeek = weekdays[date.getDay()];
  const day = date.getDate();
  const month = date.toLocaleString(language === 'en' ? 'default' : 'ro-RO', {month: 'short'});

  const isToday = new Date().toDateString() === date.toDateString();

  let daySuffix = '';
  if (language === 'en') {
    if (day === 1 || day === 21 || day === 31 ) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }
  }

  const today = language === 'en' ? 'Today' : 'Azi';

  return (isToday ? today : dayOfWeek) + ', ' + day + daySuffix + ' ' + month;
};

const getDateValue = (dateString, daysToAdd = 0) => {
  const date = new Date(dateString);

  date.setDate(date.getDate() + daysToAdd);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const todayWTmz = () =>{
  const now = new Date();
  return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
}