export const isToday = (date) =>
  new Date().toDateString() === date.toDateString();

export const isTomorrow = (date) => {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  return tomorrow.toDateString() === date.toDateString();
};

export const isAfterTomorrow = (date) => {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  return date > tomorrow
}

export const isInPast = (string) => {
  if (!string) return false;
  const date = new Date(string);
  const currentDate = new Date();
  return date < currentDate;
};

export const isInLocalPast = (string) => {
  if (!string) return false;
  const date = new Date(string);
  const currentDate = new Date(getLocalDateInStringFormat());
  return date < currentDate;
};

export const getLocalDateInStringFormat = () => {
  return new Date().toLocaleDateString('en-GB').split("/").reverse().join("-");
};

export function formatDate(date, locale) {
  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(new Date(date));
}

export function formatHour(date) {
  return new Intl.DateTimeFormat('en-GB', {
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date));
}

export function isSameDay(d1Date, d2Date) {
  const d1 = new Date(d1Date);
  const d2 = new Date(d2Date);

  if (!d1Date || !d2Date) return false;

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export const areISOStringsEqual = (isoString1, isoString2) => {
  const date1 = new Date(isoString1);
  const date2 = new Date(isoString2);

  // Compare year, month, day, hour, and minute
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes();
};

export const checkOpeningHours = (openingHours) => {
  console.log(openingHours)
  const now = new Date();
  const currentDay = now.toLocaleDateString('en-US', { weekday: 'long' }); 
  const currentTime = now.getHours() * 60 + now.getMinutes(); 

  const todayHours = openingHours.find(day => day.name === currentDay);
  
  if (!todayHours) return false;

  const [openHour, openMin] = todayHours.openAt.split(':').map(Number);

  const closingTime = todayHours.closeAt !== "00:00" ? todayHours.closeAt : "24:00";
  const [closeHour, closeMin] = closingTime.split(':').map(Number);
  
  const openTime = openHour * 60 + openMin;
  const closeTime = closeHour * 60 + closeMin;

  return currentTime >= openTime && currentTime <= closeTime;
};